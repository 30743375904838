import React, {useEffect, useState} from "react";
import { Bar, Line } from "react-chartjs-2";
import tresoBaton from "../../assets/img/tresoBaton.png"
import tresoLinear from "../../assets/img/tresoLinear.png";
import resultatBaton from "../../assets/img/resultatBaton.png";
import resultatLinear from "../../assets/img/resultatLinear.png";
import caBaton from "../../assets/img/caBaton.png";
import caLinear from "../../assets/img/caLinear.png";
import fraisBaton from "../../assets/img/fraisBaton.png";
import fraisLinear from "../../assets/img/fraisLinear.png";

function ResultsChart({ dataset, months, statSelect }) {
  const [switchGraph, setSwitchGraph] = useState(true);

  useEffect(() => {
    console.log(switchGraph)
  }, [switchGraph]);


  const stateBar = {
    labels: months,
    datasets: dataset,
  };

  const stateLine = {
    labels: months,
    datasets: dataset.map((el) => {
      el.fill = false;
      el.lineTension = 0;
      el.borderColor = el.backgroundColor;
      return el;
    }),
  };

  const optionsBar = {
    responsive: true,
    scales: {
      x: {
        categoryPercentage: 0.7,
        barPercentage: 0.5,
      },
      y: {
        ticks: {
          callback: function(value) {
            return new Intl.NumberFormat('fr-FR', {
              style: 'decimal',
              maximumFractionDigits: 4,
              useGrouping: true,
            }).format(value);
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          boxWidth: 40,
          padding: 20,
          pointStyle: 'circle', // Style des points rond
        },
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            let label = tooltipItem.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += new Intl.NumberFormat('fr-FR', {
              style: 'decimal',
              maximumFractionDigits: 4,
              useGrouping: true,
            }).format(tooltipItem.raw);
            return label;
          },
        },
      },
    },
  };
  
  const optionsLine = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        y1: {
          display: true,
          position: 'left',
          ticks: {
            callback: function(value) {
              return new Intl.NumberFormat('fr-FR', {
                style: 'decimal',
                maximumFractionDigits: 4,
                useGrouping: true,
              }).format(value);
            },
          },
        },
        y2: {
          display: false,
          position: 'right',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: 40,
          padding: 20,
          pointStyle: 'circle', // Style des points rond
        },
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            let label = tooltipItem.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += new Intl.NumberFormat('fr-FR', {
              style: 'decimal',
              maximumFractionDigits: 4,
              useGrouping: true,
            }).format(tooltipItem.raw);
            return label;
          },
        },
      },
    },
  };  

  return (
    <React.Fragment>
      {switchGraph ? (
        <section className="w-full graphs flex flex-col lg:flex-row 2xl:flex-row justify-between mb-24">
          <div className="w-full lg:w-5/12 2xl:w-5/12 chartClass">
            <div className=" mt-4">
              {statSelect === 1 && (
                  // fond #F2F5FF
                  // bordure D7E0FF
                  <div className="flex">
                    <button className={`mr-6 px-4 py-2 rounded-lg  bg-[#F2F5FF] border-2 border-[#D7E0FF]`}  onClick={() => {setSwitchGraph(true)}}>
                      <img src={tresoBaton} className={`w-[45px] h-[45px]`} alt=""/>
                    </button>

                    <button className={`p-4 rounded-lg bg-white`} onClick={() => {setSwitchGraph(false)}}>
                      <img src={tresoLinear} className={` w-[50px] h-[25px] `} alt=""/>
                    </button>
                  </div>
              )}
              {statSelect === 2 && (
                  // fond #DFF4F4
                  // bordure 9DDDDD
                  <div className="flex">
                    <button className={`mr-6 px-4 py-2 rounded-lg  bg-[#DFF4F4] border-2 border-[#9DDDDD]`}  onClick={() => {setSwitchGraph(true)}}>
                      <img src={resultatBaton} className={`w-[45px] h-[45px]`} alt=""/>
                    </button>

                    <button className={`p-4 rounded-lg bg-white`} onClick={() => {setSwitchGraph(false)}}>
                      <img src={resultatLinear} className={` w-[50px] h-[25px] `} alt=""/>
                    </button>
                  </div>

              )}
              {statSelect === 3 && (
                  // fond #FFF3F6
                  // bordure #FF6489
                  <div className="flex">
                    <button className={`mr-6 px-4 py-2 rounded-lg  bg-[#FFF3F6] border-2 border-[#FF6489]`}  onClick={() => {setSwitchGraph(true)}}>
                      <img src={caBaton} className={`w-[45px] h-[45px]`} alt=""/>
                    </button>

                    <button className={`p-4 rounded-lg bg-white`} onClick={() => {setSwitchGraph(false)}}>
                      <img src={caLinear} className={` w-[50px] h-[25px] `} alt=""/>
                    </button>
                  </div>

              )}
              {statSelect === 4 && (
                  // fond #FEEFE7
                  // bodure #FFA471
                  <div className="flex">
                    <button className={`mr-6 px-4 py-2 rounded-lg  bg-[#FEEFE7] border-2 border-[#FFA471]`}  onClick={() => {setSwitchGraph(true)}}>
                      <img src={fraisBaton} className={`w-[45px] h-[45px]`} alt=""/>
                    </button>

                    <button className={`p-4 rounded-lg bg-white`} onClick={() => {setSwitchGraph(false)}}>
                      <img src={fraisLinear} className={` w-[50px] h-[25px] `} alt=""/>
                    </button>
                  </div>

              )}


            </div>
            <div className="parent-div mt-4">
              <div className="text font-bold text-black mb-8 text-xl">
                  {statSelect === 1 && (
                      <h3>Évolution de trésorerie par période</h3>
                  )}
                  {statSelect === 2 && (
                      <h3>
                          <span className="uppercase">Résultat HT</span> par période
                      </h3>
                  )}
                  {statSelect === 3 && (
                      <h3>
                          <span className="uppercase">CA HT</span> par période
                      </h3>
                  )}
                  {statSelect === 4 && (
                      <h3>
                          <span className="uppercase">Dépenses HT</span> par période
                      </h3>
                  )}
              </div>

              <Bar size="small" data={stateBar} options={optionsBar} width={100} height={60} />
          </div>

          </div>
          <div className="w-full lg:w-2/12 2xl:w-2/12 order-first lg:order-none 2xl:order-none flex lg:justify-center 2xl:justify-center switchBtn">

          </div>
          {/*Deuxième graphique baton (dupliqué)*/}
          <div className="w-full lg:w-5/12 2xl:w-5/12 chartClass">
              <div className="parent-div mt-4">
                  <div className="text font-bold text-black mb-8 mt-24 text-xl">
                      {statSelect === 1 && <h3>Différence Recettes / Dépenses</h3>}
                      {statSelect === 2 && (
                          <h3>
                              <span className="uppercase">Résultat </span>cumulés par période
                          </h3>
                      )}
                      {statSelect === 3 && (
                          <h3>
                              <span className="uppercase">CA </span>cumulés par période
                          </h3>
                      )}
                      {statSelect === 4 && (
                          <h3>
                              <span className="uppercase">Dépenses </span>cumulées par période
                          </h3>
                      )}
                  </div>
                  <Bar size="small" data={stateBar} options={optionsBar} width={100} height={60} />
              </div>
          </div>

        </section>
      ) : (
          <>

            <section className="w-full graphs flex flex-col lg:flex-row 2xl:flex-row justify-between mb-24 chartContainer">


              <div className="w-full lg:w-5/12 2xl:w-5/12 chartClass">
                <div className="">

                  {statSelect === 1 && (
                      // fond #F2F5FF
                      // bordure D7E0FF
                      <div className="flex">
                        <button className={`mr-6 px-4 py-2 rounded-lg bg-white`}  onClick={() => {setSwitchGraph(true)}}>
                          <img src={tresoBaton} className={`w-[45px] h-[45px]`} alt=""/>
                        </button>
                        <button className={`p-4 rounded-lg bg-[#F2F5FF] border-2 border-[#D7E0FF]`} onClick={() => {setSwitchGraph(false)}}>
                          <img src={tresoLinear} className={` w-[50px] h-[25px] `} alt=""/>
                        </button>
                      </div>
                  )}
                  {statSelect === 2 && (
                      // fond #dff4f4
                      // bordure 9DDDDD
                      <div className="flex">
                          <button className={`mr-6 px-4 py-2 rounded-lg bg-white`}  onClick={() => {setSwitchGraph(true)}}>
                              <img src={resultatBaton} className={`w-[45px] h-[45px]`} alt=""/>
                          </button>
                          <button className={`p-4 rounded-lg bg-[#DFF4F4] border-2 border-[#9DDDDD]`} onClick={() => {setSwitchGraph(false)}}>
                              <img src={resultatLinear} className={` w-[50px] h-[25px] `} alt=""/>
                          </button>
                      </div>

                  )}
                  {statSelect === 3 && (
                      // fond #FFF3F6
                      // bordure #FF6489
                      <div className="flex">
                        <button className={`mr-6 px-4 py-2 rounded-lg bg-white`}  onClick={() => {setSwitchGraph(true)}}>
                          <img src={caBaton} className={`w-[45px] h-[45px]`} alt=""/>
                        </button>
                        <button className={`p-4 rounded-lg bg-[#FFF3F6] border-2 border-[#FF6489]`} onClick={() => {setSwitchGraph(false)}}>
                          <img src={caLinear} className={` w-[50px] h-[25px] `} alt=""/>
                        </button>
                      </div>

                  )}
                  {statSelect === 4 && (
                      // fond #FEEFE7
                      // bodure #FFA471
                      <div className="flex">
                        <button className={`mr-6 px-4 py-2 rounded-lg bg-white`}  onClick={() => {setSwitchGraph(true)}}>
                          <img src={fraisBaton} className={`w-[45px] h-[45px]`} alt=""/>
                        </button>
                        <button className={`p-4 rounded-lg bg-[#FEEFE7] border-2 border-[#FFA471]`} onClick={() => {setSwitchGraph(false)}}>
                          <img src={fraisLinear} className={` w-[50px] h-[25px] `} alt=""/>
                        </button>
                      </div>

                  )}

                </div>

                <div className="parent-div mt-4">
                  <div className="text font-semibold text-black mb-8">
                      {statSelect === 1 && (
                          <h3>Évolution de trésorerie par période</h3>
                      )}
                      {statSelect === 2 && (
                          <h3>
                              <span className="uppercase">Résultat HT</span> par période
                          </h3>
                      )}
                      {statSelect === 3 && (
                          <h3>
                              <span className="uppercase">CA HT</span> par période
                          </h3>
                      )}
                      {statSelect === 4 && (
                          <h3>
                              <span className="uppercase">Dépenses HT</span> par période
                          </h3>
                      )}
                  </div>
                  <div className="chart">
                      <Line data={stateLine} options={optionsLine}  width={100} height={60} />
                  </div>
              </div>

              </div>
              <div className="w-full lg:w-2/12 2xl:w-2/12 order-first lg:order-none 2xl:order-none flex lg:justify-center 2xl:justify-center switchBtn">
              </div>
              {/*Deuxième graphique courbes (dupliqué)*/}
              <div className="w-full lg:w-5/12 2xl:w-5/12 chartClass">
                <div className="parent-div mt-4">
                    <div className="text font-semibold text-black mb-8 mt-20">
                        {statSelect === 1 && <h3>Différence Recettes / Dépenses</h3>}
                        {statSelect === 2 && (
                            <h3>
                                <span className="uppercase">Résultats </span>cumulés par
                                période
                            </h3>
                        )}
                        {statSelect === 3 && (
                            <h3>
                                <span className="uppercase">CA </span>cumulé par période
                            </h3>
                        )}
                        {statSelect === 4 && (
                            <h3>
                                <span className="uppercase">Dépenses </span>cumulées par période
                            </h3>
                        )}
                    </div>
                    <div className="chart">
                        <Line data={stateLine} options={optionsLine} width={100} height={60} />
                    </div>
                </div>
            </div>

            </section>
          </>

      )}
    </React.Fragment>
  );
}

export default ResultsChart;