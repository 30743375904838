import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import HelpIcon from "@material-ui/icons/Help";
import { PulseLoader } from "react-spinners";
import { getItem } from "../../../services/LocaleStorage";
import { toast } from "react-toastify";
import { getRealUser } from "../../../app/user";

const PopupMessagerieClientOperation = ({ correspondant, closeModal, operation, invoice }) => {

    const [state, setData] = useState({
        messages: [],
        loading: false,
        sending: false,
        conversation: null,
    }, [])
    const [messageContent, setMessageContent] = useState('')

    const token = getItem('bbbToken')
    const realUser = useSelector(getRealUser)


    /**
     * Pagination on scroll to top of the conversation
     */
    const conversationContainer = useRef()
    const loadMoreMessagesBtn = useRef()
    const [allMessagesAreSeen, setAllMessagesAreSeen] = useState(false)
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 5,
        count: null,
    })

    /**
     * Chat
     */
    // Will display the toast notification only on the first message received
    const [notified, setNotified] = useState(false)
    const mercureUrl = process.env.REACT_APP_MERCURE_URL



    useEffect(() => {
        fetchMessages()

        const eventSource = createEventSource()
        subscribeToConversation(eventSource)

        // Initialize infinite scroll
        conversationContainer.current.addEventListener('scroll', () => {
            if(conversationContainer.current.scrollTop == 0) {
                loadMoreMessagesBtn.current.click()
            }
        })

        // Unsubscribe from the chat chanel o component did unmount
        return () => {
            eventSource.close()
        }
    }, [])


    /**
     * 
     * <<<<< Messages pagination
     */

    const loadMoreMessages = () => {
        if(pagination.page * pagination.per_page < pagination.count) {
            fetchMessages(pagination.page + 1, pagination.per_page)
        } else {
            setAllMessagesAreSeen(true)
        }
    }
    
    /**
     * Messages pagination >>>>>
     */
    
    
    const conversationDate = (date = new Date()) => {
        var value = (new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'medium' }).format(date));
        return value
    }

    const sortMessagesByCreatedAt = (messages) => {
        return messages.sort((a, b) => {
            var da = new Date(a.created_at)
            var db = new Date(b.created_at)
            if (da > db) return 1
            return -1
        })
    }

    const sendMessage = (e) => {
        e.preventDefault()
        if(!messageContent.trim()) {
            alert('Rien à envoyer!')
            return
        }

        const payload = {
            "sender_id": realUser.id,
            "receiver_id": correspondant.id,
            "message_body": messageContent.trim(),
            "related_entity_id": operation.id,
            "related_entity": "transaction",
            // "priority": 1,
            // "status": null,
        }
        setData({ ...state, sending: true })

        fetch(`${process.env.REACT_APP_API_URL}/message/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.ok) {
                response.json().then(resData => {
                    const messages = state.messages
                    messages.push(resData)
                    setData(oldState => {
                        return { ...oldState, sending: false, messages }
                    })
                })
                setMessageContent('');
                document.getElementById('msg-content').value = ''
                scrollToBottom()
            } else if (!response.ok) {
                setData({ ...state, sending: false })
                toast.error('Une erreur s\'est produite! Veuillez verifier votre requete et re-essayez')
            }
        })
            .catch(e => { })

        // Uncomment to display toast notification when you send a message
        // toast.info('Message envoyé')
    }

    const createEventSource = () => {
        // The subscriber subscribes to updates for the https://example.com/users/dunglas topic
        // and to any topic matching https://example.com/books/{id}
        const transactionID = operation.id
        const clientID = operation.user_id
        const url = new URL(mercureUrl);
        url.searchParams.append('topic', process.env.REACT_APP_API_URL + '/conversation/listen/' + clientID + '_' + transactionID);
        // The URL class is a convenient way to generate URLs such as https://localhost/.well-known/mercure?topic=https://example.com/books/{id}&topic=https://example.com/users/dunglas
        return new EventSource(url);
    }

    /**
     * 
     * @param {EventSource} eventSource 
     */
    const subscribeToConversation = (eventSource) => {
        // The callback will be called every time an update is published
        eventSource.onmessage = (res) => {
            // New message received
            const incommingMessage = JSON.parse(res.data)

            // Skip messages sent by me
            if (incommingMessage.sender_id == realUser.id) return

            const messages = state.messages
            messages.push(incommingMessage)
            setData(oldState => {
                return { ...oldState, sending: false, messages }
            })
            scrollToBottom()
            if (!notified) {
                toast.info('Nouveau message: (' + operation.label + ') ' + incommingMessage.body)
                setNotified(true)
            }
        };
    }

    const scrollToBottom = () => {
        const scrollingElement = document.querySelector('.scrollable')
        var scroller = 0
        const sc = setInterval(() => {
            scroller = scroller < scrollingElement.scrollHeight ? scroller + 10 : scroller
            scrollingElement.scrollTop = scroller
            if(scroller >= scrollingElement.scrollHeight) clearInterval(sc)
        }, 1);
    }

    const conversation = () => {
        return (
            <React.Fragment>
                <div className="chat">

                    { (state.loading) ?
                        <p className="text-center">
                            <PulseLoader color={'purple'} size={15} />
                        </p> : ''
                    }

                    {sortMessagesByCreatedAt(state.messages).map(message =>
                        <div key={message.id} className={"cnv " + (message.sender_id == correspondant.id ? 'other' : 'me')}>
                            <div className="name">
                                <h4>{message.sender_id == correspondant.id ? correspondant.display_name : realUser.display_name}</h4>
                                <span>{conversationDate(new Date(message.created_at))}</span>
                                {/* <span>Jan 19, 2021, 08:22 AM</span> */}
                            </div>
                            <div className="text">
                                <p>{message.body}</p>
                            </div>
                        </div>
                    )}
                </div>
                <form className="add-msg" onSubmit={e => sendMessage(e)}>
                    <div>
                        <textarea id="msg-content" onChange={e => setMessageContent(e.target.value)} type="text" placeholder="Envoyer une réponse ..." defaultValue={messageContent}></textarea>
                    </div>
                    <div>
                        {
                            state.sending ?
                                <PulseLoader color={'purple'} size={10} />
                                : <ArrowUpwardIcon />
                        }
                        <input type="submit" />
                    </div>
                </form>
            </React.Fragment>
        )
    }

    /**
     * 
     * @param {object} conversationID 
     * @param {int} page 
     * @param {int} perPage 
     */
    const fetchMessages = async (page = 1, perPage = 10) => {
        if (!operation.conversation) {
            return
        }
        setData({ ...state, loading: true })
        fetch(`${process.env.REACT_APP_API_URL}/conversation/${operation.conversation.id}/messages?page=${page}&per_page=${perPage}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setData((oldState) => ({ ...oldState, messages: data.data, loading: false }))
                    scrollToBottom()
                })
            } else if (!response.ok) {
                setData({ ...state, loading: false })
                return new Promise((resolve, reject) => reject('Une erreur s\'est produite! Veuillez verifier votre requete et re-essayez'))
            }
        })
            .catch(e => { })
    }

    return (
        <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
            <div className="w-2/3 mx-auto bg-white rounded-lg shadow-xl p-8 z-100">
                <div className="parent-table w-full">

                    <div className="w-full h-full fixed top-0 left-0 flex text-black z-500000 bg-opacity-50 bg-black items-center desktop-operation">
                        <div ref={conversationContainer} className="scrollable w-2/3 mx-auto overflow-y-scroll max-h-96 2xl:max-h-full 2xl:overflow-y-hidden bg-white rounded-lg shadow-xl p-8 z-100 relative">
                            <button type="button" className="float-right pt-0" onClick={closeModal}><CloseIcon /></button>
                            <button ref={loadMoreMessagesBtn} onClick={() => loadMoreMessages()}></button>
                            <div className="w-full flex flex-col h-full">
                                {/*Enlever le bg-color blue*/}
                                <section className="flex affect-aperc mb-8">
                                    <div className="affect w-full mr-4">

                                        <div className="flex justify-between items-center section-op">
                                            <table className="mr-2 affectations-piece">
                                                <thead>
                                                    <tr>
                                                        <th>DATE</th>
                                                        <th>LIBELLÉ DES OPÉRATIONS</th>
                                                        <th>Montant HT</th>
                                                        <th>TVA</th>
                                                        <th>Montant TTC</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{operation && operation.operation_date.split(' ')[0]}</td>
                                                        <td> {operation && operation.label} </td>
                                                        <td>{operation && operation.amount}</td>
                                                        <td>{operation && operation.vat}</td>
                                                        <td>{operation && operation.amount_with_vat}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </section>

                                <section className="flex flex-col msg-diag w-full">

                                    <div className="msg w-full">

                                        <h3>Messagerie de l’opération</h3>
                                        {
                                            !allMessagesAreSeen ? '' : <p className="text-center py-2 text-lilaFonce">C'est le tout début de la conversation!</p>
                                        }
                                        {/* Conversation */}
                                        {conversation()}

                                    </div>

                                </section>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PopupMessagerieClientOperation