import React, {useState, useEffect} from 'react'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HelpIcon from "@material-ui/icons/Help";
import Loader from '../../components/Loader';
import nomail from "../../assets/img/nomail.png";
import triangleDown from "../../assets/img/triangleDown.png";
import triangleUp from "../../assets/img/triangleUp.png";
import {
    Add,
    ArrowDownward,
    DeleteOutlineOutlined,
} from "@material-ui/icons";
import AffectAperc from "../client/affectAperc";
import {getItem} from "../../services/LocaleStorage";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {getRealUser, getUser} from "../../app/user";
import BackupIcon from "@material-ui/icons/Backup";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteIcon from "@material-ui/icons/Delete";
import PopupImportVente from "./PopupImportVente";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import PopupDeleteRowVente from "./PopupDeleteRowVente";
import {getBankAccount, getDateFilter} from "../../app/filters";
import Pagination from "../Pagination";
import http from "../../services/Http";
import {PulseLoader} from "react-spinners";
import {useDropzone} from "react-dropzone";
import PopupMessagerieClientOperation from "../Admin N/Messagerie/PopupMessagerieClientOperation";
import PopupBlockTransactions from "../dashboard/PopupBlockTransactions";
import PopupConfirmUnblock from "../dashboard/PopupConfirmUnblock";
import {setLoaderSubscribe, setLoaderUnsubscribe} from "../../app/loader";
import PopupConfirmFecExport from "../dashboard/PopupConfirmFecExport";
import lock from "../../assets/img/lock.png";
import unlock from "../../assets/img/unlock.png";
import exportArrow from "../../assets/img/exportArrow.png";
import paiementSuccess from "../../assets/img/paiementSuccess.png";
import corbeille from "../../assets/img/corbeille.png";
import lockFilter from "../../assets/img/lockFilter.png";
import unlockFilter from "../../assets/img/unlockFilter.png";
import lockWhite from "../../assets/img/lockWhite.png";
import edit from "../../assets/img/edit.png";
import lockLibelle from "../../assets/img/lockLibelle.png";
import blockFilterBlack from "../../assets/img/blockFilterBlack.png";
import unblockFilterBlack from "../../assets/img/unblockFilterBlack.png";
import notPayed from "../../assets/img/notPayed.png";
import partiallypayed from "../../assets/img/partiallyPayed.png";
import formatCurrency from "../../utils/formatAmount";

const VenteTable = ({
    loading,
    fetchEarnings,
    badges,
    earnings,
    loadingComp,
    setSortTerms,
    setSortOrder,
    updateBoutonTableauFilters,
    buttonsTableFilters,
    displayBlockedRow,
    displayUnblockedRow,
    toggleDisplayBlockedRow,
    toggleDisplayUnblockedRow
}) => {

    const [loadingComponent, setLoadingComponent] = useState(false)
    const [dataProgressBar, setDataProgressBar] = useState({});
    const [pourcentageEncaisse, setPourcentageEncaisse] = useState(0);
    const [msgModalOpen, setMsgModalOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false)
    const [affectVenteModale, setAffectVenteModale] = useState(false);
    const [rowToAffect, setRowToAffect] = useState({});
    const [afficheRow, setAfficheRow] = useState(false);
    const [nameFile, setNameFile] = useState('')
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [importVenteModalOpen, setImportVenteModalOpen] = useState(false);
    const [fileInInput, setFileInInput] = useState(false);
    const realUser = useSelector(getRealUser)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [idToSupp, setIdToSupp] = useState('')
    const [isLastRow, setIsLastRow] = useState(false);
    const [afficheBlockModale, setAfficheBlockModale] = useState(false);
    const [unblockVenteModale, setUnblockVenteModale] = useState(null);
    const [files, setFiles] = useState([]);
    const [showCompleteProfileWarning, setShowCompleteProfileWarning] = useState(false);
    const [afficheFecExportModale, setAfficheFecExportModale] = useState(false);
    const [displayBlockAllButton, setDisplayBlockAllButton] = useState(true);
    const [invoiceNotEditable, setInvoiceNotEditable] = useState([]);
    const [toggleUseOcr, setToggleUseOcr] = useState(false);

    const [variables, setVariables] = useState({
        'stock': null,
        'vente_non_facturee': null,
        'stock_n-1': null,
        'clients_rattaches_n-1': null,

    });
    const token = getItem('bbbToken')
    const dispatch = useDispatch()
    const bankAccount = useSelector(getBankAccount)

    useEffect(() => {
        earnings.data.some(el => !el.is_not_editable && el.sub_category !== null) ? setDisplayBlockAllButton(true) : setDisplayBlockAllButton(false)
    }, [earnings]);

    const openMsgModale = (invoice) => {

        console.log(invoice)
        const id = invoice.id
        setRowToAffect(earnings.data.find(elem => elem.id === id))
        setMsgModalOpen(true)
    }

    const closeModal = () => {
        setImportModalOpen(false)
        setImportVenteModalOpen(false)
        setMsgModalOpen(false)
    }

    const declenchInput = () => {
        document.getElementById('invoice-operation-input').click()
    }

    const changeInputUpload = (e) => {
        let files;
        if (e.target.files) {
            files = e.target.files;
        }
        if (e.dataTransfer && e.dataTransfer.files) {
            files = e.dataTransfer.files;
        }
        
        let totalSize = 0;
        Array.from(files).forEach((file) => {
            totalSize += file.size;

            if ((file.size / 1024) / 1024 > 20) {
                toast.error('Un seul document ne doit pas dépasser 20MB');
                removeAttachment();
                return;
            }
        });

        const isAllowedSize = ((totalSize / 1024) / 1024 <= 128) && files.length <= 50;
        if (!isAllowedSize) {
            toast.error('Limite de 50 fichiers et 128MB en total est dépassée !');
            removeAttachment();
            return;
        }

        if (files.length == 1) {
            setFileInInput(true)
            let placeholder = document.getElementById('file-placeholder');
            const imgSrc = URL.createObjectURL(files[0]);
            const imgEmbed = `<embed className="object-cover h-full" src="${imgSrc}" alt="" id="balise-img"/>`;
            placeholder.innerHTML = imgEmbed;
        } else if (files.length > 1) {
            setFileInInput(true)
            let placeholder = document.getElementById('file-placeholder');
            placeholder.innerHTML = `<span>${files.length} fichiers séléctionnés</span>`;
        } else {
            setFileInInput(false)
        }
    }

    // Glisser - déposer file
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    const dragNDrop = (e) => {
        document.getElementById('invoice-operation-input').files = e.dataTransfer.files;
        changeInputUpload(e);
    }

    const removeAttachment = () => {
        document.getElementById("invoice-operation-input").value = null
        setFileInInput(false)
    }

    const uploadInvoiceVente = async (e) => {
        const token = getItem('bbbToken')

        const form = new FormData(document.getElementById('send-invoice'))
        form.append('client_id', frontUser.id)
        form.append('invoice_type', 'vente')
        form.append('use_ocr', toggleUseOcr)


        if (fileInInput) {
            const toastId = toast.loading('Analyse est en cours, cette opération peut prendre quelques minutes...')
            setFileInInput(false)
            const response = await fetch(process.env.REACT_APP_API_URL + '/invoice/scan', {
                    method: 'POST',
                    requirements: token,
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    body: form
                })

            if (response.ok) {
                toast.update(toastId, {
                    render: "L'ajout a été effectué avec succès",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 3000
                })
                response.json().then((json) => {
                    //Permet de se retrouver sur le bon onglet après fermeture de la modale
                    fetchEarnings(earnings.per_page, earnings.page)
                    document.getElementById("invoice-operation-input").value = null
                    fetchProgressBar()
                    widthProgressBar()
                })
            }else{
                toast.update(toastId, {
                    render: "L'ajout a échoué !",
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 3000
                })
            }
        }
    }

    const dateFilters = useSelector(getDateFilter)

    //Chaque fois que la date change charger les variables complémentaire
    useEffect(() => {
        if (dateFilters[0] === null || dateFilters[1] === null) {
            return
        }
        Promise.all(Object.getOwnPropertyNames(variables).map(prop => fetchComplementaryVariable(prop)))
            .then(responses => {
                let variaComp = {...variables}
                Object.getOwnPropertyNames(variables).forEach((propri, index) => {
                    variaComp[propri] = responses[index].length !== 0 ? responses[index][0] : null
                })
                setVariables(variaComp)
            })
        fetchProgressBar()
        widthProgressBar()
    }, [dateFilters])

    //Charge les variables complémentaires
    const fetchComplementaryVariable = (prop) => {
        return new Promise((resolve, reject) => {
            const token = getItem('bbbToken')
            const periodFrom = dateFilters[0].getFullYear().toString() + "-01-01"
            const periodTo = dateFilters[0].getFullYear().toString() + "-12-31"

            let endpoint = process.env.REACT_APP_API_URL + '/user/' + frontUser.id + '/get-complementary-variable/' + prop + '?period_from=' + periodFrom + '&period_to=' + periodTo

            fetch(endpoint, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then((data) => {
                data.json().then(json => resolve(json))
            })
                .catch((error) => {
                    // TODO: catch update
                    reject(error.message)
                })
        })

    }

    //Chargement progress bar
    const fetchProgressBar = () => {
        const token = getItem('bbbToken')
        const periodFrom = dateFilters[0].getFullYear().toString() + "-01-01"
        const periodTo = dateFilters[0].getFullYear().toString() + "-12-31"
        let endpoint = '/stats/turnover?client_id=' + frontUser.id + '&start_at=' + periodFrom + '&end_at=' + periodTo
        setLoadingComponent(true)

        http.get(endpoint,  {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })
        .then((data) => {
            setLoadingComponent(false)
            if(data?.ca_facturee !== null && data?.ca_encaissee !== null){
                setDataProgressBar(data)
                //Pourcentage de la progressBar
                setPourcentageEncaisse((100 / data.ca_facturee) * data.ca_encaissee)
            }
        })
        .catch((error) => {
            setLoadingComponent(false)
            if(error.status === 424){
                setShowCompleteProfileWarning(true)
            }
        });
    }

    useEffect(() => {
        widthProgressBar()
    }, [pourcentageEncaisse])

    const widthProgressBar = () => {
        if (!document.getElementById('progress-bar')) return
        if (isNaN(pourcentageEncaisse)) {
            document.getElementById('progress-bar').style.width = "100%"
        } else {
            document.getElementById('progress-bar').style.width = `${pourcentageEncaisse}%`
        }
    }

    useEffect(() => {
    }, [variables])

    //Envois des variables complémentaires
    const sendVariable = (e) => {
        if (dateFilters[0] === null || dateFilters[1] === null || dateFilters[0].getFullYear() !== dateFilters[1].getFullYear()) {
            toast.info('Veuillez saisir deux dates dans le menu de gauche d\'un ans d\'exercice.')
            return
        }
        let dateStart = dateFilters[0].getFullYear().toString() + "-01-01"

        let dateEnd = dateFilters[1].getFullYear().toString() + "-12-31"
        const token = getItem('bbbToken')

        const form = new FormData()
        form.append('period_from', dateStart)
        form.append('period_to', dateEnd)
        form.append('name', e.target.name.toString())
        form.append('value', e.target.value.toString())

        fetch(process.env.REACT_APP_API_URL + '/user/' + frontUser.id.toString() + '/set-complementary-variable', {
            method: 'POST',
            requirements: token,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: form
        }).then((response) => {
            if (response.ok) {
                response.json().then((json) => {
                    toast.success('Ajout de la variable complémentaire')
                })
            } else {
                toast.error('L\'ajout de la variable complémentaire a échoué.')
            }
        })
    }
    const close = () => {
        setAffectVenteModale(false)
        setAfficheBlockModale(false)
        setUnblockVenteModale(null)
        setAfficheFecExportModale(false)
    }
    const openAffectModale = (id) => {
        setRowToAffect(earnings.data.find(elem => elem.id === id))
        setAffectVenteModale(true)
    }
    const frontUser = useSelector(getUser)

    const closeModalDeleteRow = () => {
        setDeleteModalOpen(false)
    }
    const toDeleteVente = (id) => {
        setIdToSupp(id)
        //met a true si cest la derniere du tableau
        setIsLastRow(!earnings.data.some(el => el.id > id))
        setDeleteModalOpen(true)
    }

    const newManualRow = () => {
        const token = getItem('bbbToken')
        setAfficheRow(false)
        setIsLoading(true);
        const form = new FormData(document.getElementById("new-manual-row"))
        form.append('client_id', frontUser.id)
        form.append('type', 'vente')
        form.append('affectation_status', 'false')
        form.append('vat_value', form.get('amount_with_vat') - form.get('amount'))

        fetch(process.env.REACT_APP_API_URL + '/invoice', {
            method: 'POST',
            requirements: token,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: form
        })
        .then((response) => {
            if (response.ok) {
                setIsLoading(false);
                response.json().then((json) => {
                    toast.success('Ajout de la facture de vente avec succès.')
                    fetchEarnings()
                    fetchProgressBar()
                    widthProgressBar()

                })
            } else {
                setIsLoading(false);
                toast.error('L\'ajout de la facture a échoué.')
            }
        })
    }

    useEffect(() => {
        fetchProgressBar()
        widthProgressBar()

    }, [earnings]);

    const attachFile = () => {
        document.getElementById('declenche-input-manual-row').click()
    }

    const renderRow = () => {
        if (afficheRow) {
            return <form id="new-manual-row">
                <table className="min-w-full table overflow-hidden rounded-2xl">
                    <thead>
                        <tr className="capitalize text-sm text-left bg-gris p-8">
                            <th className="w-10">
                                <div className="flex justify-center items-center w-full">
                                    <input name="operation_date" type="date" className="bg-none-input w-full" size="4" />
                                </div>
                            </th>
                            <th className="min-w-8">
                                <div className="flex justify-center items-center w-full">
                                    <input name="reference" type="text" className="bg-none-input w-full" placeholder="Reference" size="8"/>
                                </div>
                            </th>
                            <th className="min-w-8">
                                <div className="flex items-center w-full">
                                    <input name="label" type="text" className="bg-none-input text-red-500 w-full" placeholder="Libellé" size="8"/>
                                </div>
                            </th>
                            <th>
                            </th>
                            <th className="none-mobile min-w-24">
                                <div className="text flex items-center w-full">
                                    <input type="text" name="amount" className="bg-none-input w-full" placeholder="Mont. HT"
                                        size="4"/>
                                </div>
                            </th>
                            <th className="none-mobile min-w-24">
                                <div className="text flex items-center w-full">
                                    <input type="text" name="vat" className="bg-none-input w-full" placeholder="TVA"
                                        size="4"/>
                                </div>
                            </th>
                            <th className="none-mobile min-w-24">
                                <div className="text flex items-center w-full">
                                    <input type="text" name="amount_with_vat" className="bg-none-input w-full"
                                        placeholder="Mont. TTC" size="4"/>
                                </div>
                            </th>
                            <th className="none-mobile">
                                <div className="text text-center">
                                    <div className="relative">
                                        {/*Joindre une piece jointe au clique sur le trombone*/}
                                        {/*<button type="button" onClick={attachFile} className="label resize-text">*/}
                                        {/*    <AttachFileIcon/>*/}
                                        {/*</button>*/}
                                        <button type="button" className="label resize-text">
                                            <AttachFileIcon/>
                                        </button>
                                        <span className="bg-redCorail top-icon-rounded"></span>
                                    </div>
                                    <input type="file" name="receipt" id="declenche-input-manual-row" className="hidden"/>
                                </div>
                            </th>
                            <th className="none-mobile">
                                <div className="text text-center">
                                    <button className="rounded-xl p-2 bg-redCorail text-white" disabled>Affecter
                                    </button>
                                </div>
                            </th>
                            <th className="">
                                <div className="flex px-6 justify-center items-center">
                                    <span>tirelire</span>
                                </div>
                            </th>
                            <th className="w-min">
                                <div className="flex px-6 justify-center items-center">
                                    <button type="button" onClick={newManualRow}
                                            className="ml-2 bg-lilaFonce text-white p-2 rounded-lg"><ArrowDownward/>
                                    </button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                </table>
            </form>

        }

        return "";
    }

    //Blocage/Déblocage des lignes de vente
    const ifVenteBlockChecked = () => {
        if (document.querySelectorAll('.block-invoice-vente-checkbox:checked').length > 0) {
            setAfficheBlockModale(true)
        }
    }
    const blockVenteChecked = () => {
        setLoadingComponent(true);
        dispatch(setLoaderSubscribe())
        fetch(process.env.REACT_APP_API_URL + '/invoices/block', {
            method: 'POST', 
            'Access-Control-Allow-Credentials': true, 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` 
            }, 
            body: JSON.stringify({invoices_id: invoiceNotEditable.map(el => el.id)})
        })
        .then(async (data) => {
            setLoadingComponent(false);
            dispatch(setLoaderUnsubscribe())
            const response = await data.json()
            if (data.ok) {
                fetchEarnings()
                toast.success("Le blocage des factures de vente a été effectué avec succès.")
            } else {
                toast.error(response.message ?? "Le blocage des factures de vente a échoué.")
            }
           setInvoiceNotEditable([])
        });
    }

    const ifVenteBlockedUnchecked = (invoice) => {
        if(!invoice.is_not_editable ){
            setInvoiceNotEditable([invoice])
        }else{
            setUnblockVenteModale(invoice)
        }
    }

    useEffect(() => {
        if(invoiceNotEditable.length > 0) {
            setAfficheBlockModale(true)
        }else{
            setAfficheBlockModale(false)
        }
    }, [invoiceNotEditable]);

    const unblockinvoice = () => {
        setLoadingComponent(true);
        fetch(process.env.REACT_APP_API_URL + '/invoice/unblock', {
            method: 'POST', 
            'Access-Control-Allow-Credentials': true, 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, 
            }, 
            body: JSON.stringify({id: unblockVenteModale.id})
        })
        .then((data) => {
            setLoadingComponent(false);
            dispatch(setLoaderUnsubscribe())
            if (data.ok) {
                fetchEarnings()
                toast.success("La facture de vente a été débloquée avec succès.")
            } else {
                toast.error("La facture de vente n'a pas été débloquée.")
            }
            setUnblockVenteModale(null)
        });
    }

    const ifVenteFecChecked = () => {
        setAfficheFecExportModale(true)
    }

    const checkAllBlockInvoices = () => {
        // tableau des opérations non bloquées pour les bloquer lors du clic sur "tous"
        const invoiceFiltered = earnings.data.filter(el => el.is_not_editable !== true && el.sub_category !== null)
        setInvoiceNotEditable(invoiceFiltered)
    }

    const displaySubCategoryName = (invoice) => {
		let subCategoryName = '';

		if (invoice?.sub_category?.display_name) {
			subCategoryName = invoice?.sub_category?.display_name;
		} else {
			// return <HelpIcon fontSize="small" />;
			return null;
		}

		if (invoice?.is_sub_categ_suggested) {
			return <span style={{ color: '#FD5353' }}>{subCategoryName} <HelpIcon fontSize="small" /></span>
		} else {
			return <span style={{ color: '#818181' }}>{subCategoryName}</span>
		}
	}

    const styles = {
		subCategoryName: {
			fontSize: '14px',
			fontWeight: '600',
		},
		invoiceLabel: {
			fontSize: '16px',
			fontWeight: '600',
		}
	}

    return (
        <React.Fragment>
            <Loader active={isLoading}/>

            {affectVenteModale &&
                <AffectAperc 
                    rowToAffect={rowToAffect} 
                    close={close} 
                    fetchEarnings={() => fetchEarnings(earnings.per_page, earnings.page)}
                    isLastInvoice={!earnings.data.some(el => el.id > rowToAffect.id)}
                />}
            <div className="w-full py-20 flex">
                {/*Affiche ces variables seulement si les données complémentaires du client (profile) ont été renseignés*/}
                {showCompleteProfileWarning ? <div className="w-3/6">
                    <p>{realUser.role !== "client" ? "Vous devez compléter le profil du client pour voir ses statistiques" : "Votre profil doit être complété pour voir vos statistiques"}</p>
                </div> :  <div className="w-3/6" id="variables">

                    <div className="w-full flex">
                        <div className="flex justify-between w-full text-black">
                            <div>
                                <p>CA HT Facturé</p>
                                <p className="text-grisFonce font-bold">{loadingComponent ? <PulseLoader
                                    color={'gray'} size={7}/> : dataProgressBar?.ca_facturee ?? '--'}€</p>
                            </div>
                            <div>
                                <p>CA HT Prévu</p>
                                <p className="text-lilaFonce font-bold">--</p>
                            </div>
                            <div>
                                <p>CA HT Encaissé</p>
                                <p className="text-ciel font-bold ">{loadingComponent ? <PulseLoader
                                    color={'gray'} size={7}/> : dataProgressBar?.ca_encaissee ?? '--'}€</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-col w-full pt-8">
                        <div className="flex text-black justify-between">
                            <p>Encaissement du CA HT sur la période en cours</p>
                            <p>{isNaN(parseInt(pourcentageEncaisse)) ? '100' : parseInt(pourcentageEncaisse)}%</p>
                        </div>
                        <div className="w-full rounded-lg h-8 bg-gris mt-3">
                            <div className="bg-gradient-to-r from-purple-700 to-blue-500 h-8" id="progress-bar" onChange={widthProgressBar}></div>
                        </div>
                    </div>
                    <div className="flex text-black pt-8 justify-between w-full">
                        <div className="font-bold">
                            <div className="py-1 mb-3"><p>Stock</p></div>
                            <div className=" py-1"><p>Vente non facturées en cours</p></div>
                        </div>
                        <div className="flex-col">

                            <div className="h-8 mb-3">
                                <input 
                                    type="text" 
                                    className="py-1 bg-gris rounded-lg w-full text-lilaFonce text-center"
                                    name="stock"
                                    defaultValue={variables.stock?.value ?? ''}
                                    onBlur={(e) => sendVariable(e)}
                                />
                            </div>

                            <div className="h-8">
                                <input 
                                    type="text" 
                                    className="py-1 bg-gris rounded-lg full text-lilaFonce text-center"
                                    name="vente_non_facturee" 
                                    defaultValue={variables.vente_non_facturee?.value ?? ''}
                                    onBlur={(e) => sendVariable(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex text-black pt-8 justify-between w-full">
                        <div className="font-bold">
                            <div className="py-1 mb-3"><p>Stock n-1</p></div>
                            <div className=" py-1"><p>Clients rattachés n-1</p></div>
                        </div>
                        <div className="flex-col">
                            <div className="h-8 mb-3">
                                <input 
                                    type="text" 
                                    className=" py-1 bg-gris rounded-lg w-full text-lilaFonce text-center"
                                    name="stock_n-1" 
                                    defaultValue={variables["stock_n-1"]?.value ?? ''}
                                    onBlur={(e) => sendVariable(e)}
                                />
                            </div>
                            <div className="h-8">
                                <input 
                                    type="text" 
                                    className=" py-1 bg-gris rounded-lg full text-lilaFonce text-center"
                                    name="clients_rattaches_n-1"
                                    defaultValue={variables["clients_rattaches_n-1"]?.value ?? ''}
                                    onBlur={(e) => sendVariable(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="w-3/6 flex justify-center">
                    <div className="vente-upload">
                        <div className="w-full rounded-lg items-center mb-2">
                            <div className="w-upload text-black h-full mx-auto bg-gris rounded-xl">
                                <h3 className="text-center mb-0 p-2 font-bold w-full">Joindre un fichier</h3>
                                <div className="bg-gris vente-upload w-full rounded-lg flex justify-center items-center">
                                    <div
                                        className={`flex justify-center items-center ${fileInInput ? "" : " border-dashed border-2 border-grisDashed "} vente-upload charge-img rounded-xl mx-auto`}
                                        onClick={declenchInput} 
                                        onDrop={(e) => { dragNDrop(e) }}
                                    >
                                        <div id="file-placeholder" className={!fileInInput ? "hidden" : ""}></div>
                                        <div
                                            className={fileInInput ? "hidden" : "flex flex-col justify-center text-center"} 
                                            title="Importer une facture de vente"
                                        >
                                            <div>
                                                <button type="button" className="svg-upload"><BackupIcon/></button>
                                            </div>
                                            <div className="text" title="Importer une facture de vente">
                                                <form id="send-invoice">
                                                    <input 
                                                        type="file" 
                                                        name="receipt[]" 
                                                        id="invoice-operation-input"
                                                        className="hidden" 
                                                        multiple="multiple"
                                                        onChange={(e) => changeInputUpload(e)}
                                                    />
                                                </form>
                                                <h5 className="font-semibold">Charger un fichier</h5>
                                                <h6>PDF, DOC, PPT, JPG, PNG</h6>
                                                <span>{nameFile}</span>

                                                <label className="relative my-2 inline-flex items-center cursor-pointer" onClick={(e)=> {e.stopPropagation()}}>
                                                    <input type="checkbox" checked={toggleUseOcr} className="sr-only peer shadow-inner" onChange={()=> {setToggleUseOcr(!toggleUseOcr)}}/>
                                                        <div
                                                            className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:shadow-inner rounded-full peer dark:bg-[#EFEFEF] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#5A45AA]"></div>
                                                        <span className="ms-3 text-sm font-medium text-[#5A45AA]">OCR</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <button type="button" disabled={!fileInInput}
                                    className={`w-full p-2 bg-greenSuccess rounded-xl ${fileInInput ? "" : "bg-opacity-50 cursor-not-allowed"} text-white flex items-center justify-center mr-2`}
                                    onClick={(e) => uploadInvoiceVente(e)}>
                                <DescriptionIcon/>
                                <span className="mx-auto px-6 ">Envoyer</span>
                            </button>

                            <button disabled={!fileInInput} type="button"
                                    className={`w-full p-2 bg-redCorail rounded-xl ${fileInInput ? "" : "bg-opacity-50 cursor-not-allowed"} text-white flex items-center justify-center`}
                                    onClick={removeAttachment}>
                                <DeleteIcon/>
                                <span className="mx-auto px-6">Supprimer</span>
                            </button>
                        </div>
                        <button className="mt-2" title="Importer plusieurs factures de ventes" onClick={() => {
                            setImportVenteModalOpen(true)
                        }}><LaunchOutlinedIcon/> Import
                        </button>
                        {importVenteModalOpen && <PopupImportVente close={closeModal} fetchEarnings={() => fetchEarnings(earnings.per_page, earnings.page)}/>}
                    </div>
                </div>

            </div>
            <Loader active={loading}/>

            <div className="float-right">
                {!afficheRow ? <button onClick={() => setAfficheRow(true)}><Add/></button> :
                    <button className="text-2xl font-bold" onClick={() => setAfficheRow(false)}>-</button>}
            </div>

            <div>{renderRow()}</div>
            <div className="overflow-auto rounded-lg w-full">
                <table className="w-full" style={{ tableLayout: 'fixed' }}>
                    <thead className="bgPurpuleCustom text-white">
                    <tr>
                        <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left" style={{ width: '90px' }}>
                            <div className="flex flex-col justify-start whitespace-nowrap">
                                <div className="flex items-center tracking-wide whitespace-nowrap">
                                    <span>Date</span> 
                                    <img 
                                        src={triangleDown} 
                                        alt="Triangle vers le bas" 
                                        className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                        onClick={() => {
                                            setSortTerms("operation_date"); 
                                            setSortOrder("DESC");
                                        }}
                                    /> 
                                    <img 
                                        src={triangleUp} 
                                        alt="Triangle vers le haut" 
                                        className="w-3.5 h-3.5 cursor-pointer" 
                                        onClick={() => {
                                            setSortTerms("operation_date"); 
                                            setSortOrder("ASC");
                                        }}
                                    />
                                </div>
                            </div>
                            <span className="invisible">invisible</span>
                        </th>
                        <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left" style={{ width: '130px' }}>
                            <div className="flex flex-col justify-start whitespace-nowrap">
                                <div className="flex items-center tracking-wide whitespace-nowrap">
                                    <span>Num pièce</span>
                                    <img 
                                        src={triangleDown} 
                                        alt="Triangle vers le bas" 
                                        className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                        onClick={() => {
                                            setSortTerms(""); 
                                            setSortOrder("DESC");
                                        }}
                                    /> 
                                    <img 
                                        src={triangleUp} 
                                        alt="Triangle vers le haut" 
                                        className="w-3.5 h-3.5 cursor-pointer" 
                                        onClick={() => {
                                            setSortTerms(""); 
                                            setSortOrder("ASC");
                                        }}
                                    />
                                </div>
                            </div>
                            <span className="invisible">invisible</span>
                        </th>
                        <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left" style={{ width: '300px' }}>
                            <div className="flex flex-col justify-start whitespace-nowrap">
                                <span>Libellé des documents</span>
                                <div className="flex">
                                    <span className={`px-1.5 py-1 text-base rounded-full mr-2 cursor-pointer bg-white `} onClick={() => toggleDisplayBlockedRow()}>
                                       <img src={displayBlockedRow ? blockFilterBlack : lockFilter} alt="lock"/>
                                    </span>
                                    <span className={`px-1.5 py-1 text-base rounded-full cursor-pointer bg-white`} onClick={() => toggleDisplayUnblockedRow()}>
                                        <img src={displayUnblockedRow ? unblockFilterBlack : unlockFilter} alt="unlock" />
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th className="text-sm font-semibold tracking-wide text-left" style={{ width: '80px' }}>
                            <div className="flex flex-col justify-start whitespace-nowrap">
                                <span></span>
                                <span className="invisible">invisible</span>
                            </div>
                        </th>
                        <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left">
                            <div className="flex flex-col justify-start whitespace-nowrap">
                                <div className="flex items-center tracking-wide whitespace-nowrap">
                                    <span>Mont. HT</span>
                                    <img 
                                        src={triangleDown} 
                                        alt="Triangle vers le bas" 
                                        className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                        onClick={() => {
                                            setSortTerms("amount"); 
                                            setSortOrder("DESC");
                                        }}
                                    /> 
                                    <img 
                                        src={triangleUp} 
                                        alt="Triangle vers le haut" 
                                        className="w-3.5 h-3.5 cursor-pointer" 
                                        onClick={() => {
                                            setSortTerms("amount"); 
                                            setSortOrder("ASC");
                                        }}
                                    />
                                </div>
                                <p className="whitespace-nowrap">
                                    <span className="inline-flex items-center justify-center h-6 w-6 text-sm rounded-full text-lilaFonce bg-white mr-2">-</span>
                                    <span>{formatCurrency(earnings?.totals?.credit - earnings?.totals?.vat)}</span>
                                </p>
                            </div>
                            <span className="invisible">invisible</span>
                        </th>
                        <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left">
                            <div className="flex flex-col justify-start whitespace-nowrap">
                                <div className="flex items-center tracking-wide whitespace-nowrap">
                                    <span>TVA</span>
                                    <img 
                                        src={triangleDown} 
                                        alt="Triangle vers le bas" 
                                        className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                        onClick={() => {
                                            setSortTerms("vat"); 
                                            setSortOrder("DESC");
                                        }}
                                    /> 
                                    <img 
                                        src={triangleUp} 
                                        alt="Triangle vers le haut" 
                                        className="w-3.5 h-3.5 cursor-pointer" 
                                        onClick={() => {
                                            setSortTerms("vat"); 
                                            setSortOrder("ASC");
                                        }}
                                    />
                                </div>
                                <p className="whitespace-nowrap">
                                    <span className="inline-flex items-center justify-center h-6 w-6 text-sm rounded-full text-lilaFonce bg-white mr-2">-</span>
                                    <span>{formatCurrency(earnings?.totals?.vat)}</span>
                                </p>
                            </div>
                            <span className="invisible">invisible</span>
                        </th>
                        <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left">
                            <div className="flex flex-col justify-start whitespace-nowrap">
                                <div className="flex items-center tracking-wide whitespace-nowrap">
                                    <span>Mont. TTC</span>
                                    <img 
                                        src={triangleDown} 
                                        alt="Triangle vers le bas" 
                                        className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                        onClick={() => {
                                            setSortTerms("amountWithVat"); 
                                            setSortOrder("DESC");
                                        }}
                                    /> 
                                    <img 
                                        src={triangleUp} 
                                        alt="Triangle vers le haut" 
                                        className="w-3.5 h-3.5 cursor-pointer" 
                                        onClick={() => {
                                            setSortTerms("amountWithVat"); 
                                            setSortOrder("ASC");
                                        }}
                                    />
                                </div>
                                <p className="whitespace-nowrap">
                                    <span className="inline-flex items-center justify-center h-6 w-6 text-sm rounded-full text-lilaFonce bg-white mr-2">-</span>
                                    <span>{formatCurrency(earnings?.totals?.credit)}</span>
                                </p>
                            </div>
                            <span className="invisible">invisible</span>
                        </th>
                        <th className="py-4 px-3 text-sm font-semibold tracking-wide text-center">
                            <div className="flex flex-col justify-start">
                                <div>Pièces</div>
                                <div className="flex justify-center gap-2 mt-2">
                                    <div className={`text-xs rounded-full ${!buttonsTableFilters.receipt.includes("with") ? 'text-white ' : 'text-vertBouton' }`}>
                                        <span onClick={() => updateBoutonTableauFilters('receipt', 'with')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-vertBouton ${!buttonsTableFilters.receipt.includes("with") ? 'bg-white text-vertBouton' : 'text-white bg-vertBouton' }`}>{badges.receipt?.with ?? "--"}</span>
                                    </div>
                                    <div className={`text-xs rounded-full ${!buttonsTableFilters.receipt.includes("to_be_modified") ? 'text-white ' : 'text-orange-500' }`}>
                                        <span onClick={() => updateBoutonTableauFilters('receipt', 'to_be_modified')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-orange-500 ${!buttonsTableFilters.receipt.includes("to_be_modified") ? 'bg-white text-orange-500' : 'text-white bg-orange-500' }`}>{badges.receipt?.to_be_modified ?? "--"}</span>
                                    </div>
                                    <div className={`text-xs rounded-full ${!buttonsTableFilters.receipt.includes("without") ? 'text-white ' : 'text-orange-500' }`}>
                                        <span onClick={() => updateBoutonTableauFilters('receipt', 'without')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-redCorail ${!buttonsTableFilters.receipt.includes("without") ? 'bg-white text-redCorail' : 'text-white bg-redCorail' }`}>{badges.receipt?.without ?? "--"}</span>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th className="py-4 px-3 text-sm font-semibold tracking-wide text-center">
                            <div className="flex flex-col">
                                <div>Statut</div>
                                <div className="flex justify-center gap-2 mt-2">
                                    <div className={`text-xs rounded-full ${!buttonsTableFilters.affectation.includes("with") ? 'text-white ' : 'text-vertBouton' }`}>
                                        <span onClick={() => updateBoutonTableauFilters('affectation', 'with')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-vertBouton ${!buttonsTableFilters.affectation.includes("with") ? 'bg-white text-vertBouton' : 'text-white bg-vertBouton' }`}>{badges.affectation?.without ?? "--"}</span>
                                    </div>
                                    <div className={`text-xs rounded-full ${!buttonsTableFilters.affectation.includes("to_be_modified") ? 'text-white ' : 'text-orange-500' }`}>
                                        <span onClick={() => updateBoutonTableauFilters('affectation', 'to_be_modified')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-orange-500 ${!buttonsTableFilters.affectation.includes("to_be_modified") ? 'bg-white text-orange-500' : 'text-white bg-orange-500' }`}>{badges.affectation?.to_be_modified ?? "--"}</span>
                                    </div>
                                    <div className={`text-xs rounded-full ${!buttonsTableFilters.affectation.includes("without") ? 'text-white ' : 'text-orange-500' }`}>
                                        <span onClick={() => updateBoutonTableauFilters('affectation', 'without')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-redCorail ${!buttonsTableFilters.affectation.includes("without") ? 'bg-white text-redCorail' : 'text-white bg-redCorail' }`}>{badges.affectation?.without ?? "--"}</span>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th className="py-4 px-3 text-sm font-semibold tracking-wide text-center">
                            <div className="flex flex-col">
                                <div>Paiements</div>
                                <div className="flex justify-center gap-2 mt-2">
                                    <div className={`text-xs rounded-full ${!buttonsTableFilters.payment.includes("fully") ? 'text-white ' : 'text-vertBouton' }`}>
                                        <span onClick={() => updateBoutonTableauFilters('payment', 'fully')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-vertBouton ${!buttonsTableFilters.payment.includes("fully") ? 'bg-white text-vertBouton' : 'text-white bg-vertBouton' }`}>{badges.payment?.fully ?? "--"}</span>
                                    </div>
                                    <div className={`text-xs rounded-full ${!buttonsTableFilters.payment.includes("partially") ? 'text-white ' : 'text-orange-500' }`}>
                                        <span onClick={() => updateBoutonTableauFilters('payment', 'partially')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-orange-500 ${!buttonsTableFilters.payment.includes("partially") ? 'bg-white text-orange-500' : 'text-white bg-orange-500' }`}>{badges.payment?.partially ?? "--"}</span>
                                    </div>
                                    <div className={`text-xs rounded-full ${!buttonsTableFilters.payment.includes("zero") ? 'text-white ' : 'text-orange-500' }`}>
                                        <span onClick={() => updateBoutonTableauFilters('payment', 'zero')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-redCorail ${!buttonsTableFilters.payment.includes("zero") ? 'bg-white text-redCorail' : 'text-white bg-redCorail' }`}>{badges.payment?.zero ?? "--"}</span>
                                    </div>
                                </div>
                            </div>
                        </th>
                        {/*<th className="p-4 text-sm font-semibold tracking-wide text-left"></th>*/}

                        {realUser.role !== 'client' ? <th className="p-4 text-sm font-semibold text-black bg-white whitespace-nowrap">
                            <div className="flex justify-center items-center px-4 cursor-pointer" onClick={ifVenteFecChecked}>
                                <img src={exportArrow} className="exportFec" alt=""/>
                                <span className="font-semibold text-[#9D9D9D] text-[14px] sidebar:text-[20px] ml-2">Export FEC</span>
                            </div>
                        </th> : <th></th>}
                    </tr>
                    </thead>

                    {
                        loadingComp ?
                            <tbody>
                            <tr>
                                <td className="p-0" colSpan="3">
                                    <PulseLoader
                                        color={'purple'} size={10}/>
                                </td>
                            </tr>
                            </tbody> :
                            earnings=== null || earnings.data.length === 0 ?
                                <tbody>
                                <tr>
                                    <td className="p-4" colSpan="3">Vous n'avez aucune facture de vente</td>
                                </tr>
                                </tbody> :
                            <tbody>
                            {earnings?.data.map((invoice, index) => {
                                return <tr key={invoice.id} className={`${ realUser.role === "admin_n" || realUser.role === "admin_p" ? "except-last-td" : ""}  ${index % 2 === 0 ? "bg-grisbleu" : ""}`}>
                                    <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap">{invoice.displayable_operation_date}</td>
                                    <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap">
                                        {!invoice.is_deleted ? invoice.reference : ""}
                                    </td>
                                    <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap" title={invoice.label + ' ref ' + invoice.reference} style={{ wordBreak: 'break-all', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                        <div className="flex flex-col">
                                            <span style={styles.subCategoryName}>{displaySubCategoryName(invoice)}</span>
                                                {invoice.is_deleted ? 
                                                    <span></span> 
                                                :   <div className="flex gap-1">
                                                        {invoice.is_not_editable ? <img src={lockLibelle} alt="locked" className="w-[12px] h-[15px] mr-2"/> : null}
                                                        <span className={`${invoice.label === null ? "text-redCorail" : ""}`} style={styles.invoiceLabel}>
                                                            {invoice.label ?? "???"}
                                                            <br /><span className={`${invoice.reference === null ? "text-redCorail" : ""}`}>{ invoice.reference ? `ref ${invoice.reference}` : "???" }</span> 
                                                        </span>
                                                    </div>
                                                }
                                        </div>
                                    </td>
                                    <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap text-center" onClick={() => openMsgModale(invoice)}>
                                        <img src={nomail} className="img-mailround" alt="message"/>
                                    </td>
                                    <td className={`${invoice.amount === null ? "text-redCorail" : "text-gray-700"} p-3 border-b border-gray-200 text-sm whitespace-nowrap`}>{invoice.amount ?? "???"}</td>
                                    <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap"> <span className={`${invoice.vat_value === null ? "text-redCorail" : "text-black"} mr-2`}>{ invoice.vat_value ?? "???" }</span> <span>{invoice.vat ? invoice.vat + "%" : ""}</span></td>
                                    <td className={`${invoice.amount_with_vat === null ? "text-redCorail" : "text-gray-700"} p-3 border-b border-gray-200 text-sm whitespace-nowrap`}>{invoice.amount_with_vat ?? "???"}</td>
                                    <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap">

                                        <div className="flex justify-center">
                                            {invoice.receipt_path !== null ?
                                                <a href={process.env.REACT_APP_STORAGE_URL + invoice.receipt_path}
                                                   target="_blank" rel="noreferrer">
                                                    <div className="relative p-0">
                                                        <AttachFileIcon className="fill-black"/>
                                                        <span
                                                            className={`${invoice.receipt_path === null ? "bg-redCorail" : "bg-greenSuccess"} top-icon-rounded`}></span>
                                                    </div>
                                                </a>
                                                : <div className="relative p-0">
                                                    <button type="button">
                                                        <AttachFileIcon className="fill-black"/>
                                                    </button>
                                                </div>}

                                        </div>
                                    </td>
                                    <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap">
                                        <div className="flex justify-center">
                                            {
                                                invoice.is_deleted ? 
                                                    null 
                                                :
                                                    invoice.affectation_status ?
                                                        invoice.is_not_editable ?
                                                            <div className={`rounded-full flex items-center justify-center w-11 h-11 ${invoice.is_reported ? "bg-orange-500" : "bg-greenSuccess"}`}>
                                                                <img src={lockWhite} alt="lock"/>
                                                            </div>
                                                        : 
                                                            <button className={`rounded-full flex items-center justify-center p-3 ${invoice.is_reported ? "bg-orange-500" : "bg-greenSuccess"}`} onClick={() => openAffectModale(invoice.id)}>
                                                                <img src={edit} alt="edit"/>
                                                            </button>
                                                    : 
                                                        invoice.is_not_editable ?
                                                            <div className={`rounded-full flex items-center justify-center w-11 h-11 bg-redCorail`}>
                                                                <img src={lockWhite} alt="lock"/>
                                                            </div>
                                                        : 
                                                            <button className="bg-redCorail rounded-xl px-6 py-2 text-white" onClick={() => openAffectModale(invoice.id)}>Associer</button>
                                            }
                                        </div>
                                        </td>


                                    <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap">
                                        <div className="relative group cursor-pointer flex justify-center">
                                            {invoice.payment?.payed.length === 0 ? null : <div className="absolute border border-gray-200 bg-white rounded-lg hidden group-hover:block z-10 top-4 right-8 translate-x-full -translate-y-full p-3 ">
                                                <div className="flex flex-col justify-center h-full ">
                                                    {invoice.payment.payed.map((elem, i) => {
                                                        return  <p className="flex justify-between" key={i}>
                                                            <span className="text-gray-200 mr-8">{elem.date}</span>
                                                            <span className="text-black font-bold"> {elem.amount} €</span>
                                                        </p>

                                                    })}
                                                    <p className="text-black font-semibold text-center pt-6">Restant dû <span className="text-redCorail font-bold">{invoice.payment.not_payed} €</span></p>
                                                </div>

                                            </div>}
                                            {
                                                invoice.amount_with_vat === null || invoice.amount_with_vat == invoice.payment?.not_payed ? <img src={notPayed} alt="paiement-status"/> : invoice.payment?.not_payed == 0 ?  <img src={paiementSuccess} alt="paiement-status"/> : <img src={partiallypayed} alt="paiement-status"/>
                                            }
                                        </div>
                                    </td>

                                    {realUser.role !== 'client' && !invoice.is_deleted ? <>
                                        <td className="h-full p-0">
                                            <div className="flex items-center justify-center h-12 ">
                                                {invoice.is_not_editable ?
                                                    <>
                                                        <div className="p-2 w-12 h-12 cursor-pointer" onClick={() => ifVenteBlockedUnchecked(invoice)} data-invoice-id={invoice.id}>
                                                            <img src={lock} alt="lock"/>
                                                        </div>
                                                        <div className="p-2 w-12 h-12"></div>
                                                    </>
                                                : 
                                                    <>
                                                        <div className="p-2 w-12 h-12 cursor-pointer" onClick={() => ifVenteBlockedUnchecked(invoice)} data-invoice-id={invoice.id}>
                                                            <img src={unlock} alt="lock"/>
                                                        </div>
                                                        <div className="p-2 w-12 h-12">
                                                            <button className="bg-[#D7D7D7] w-[32px] h-[32px] text-white p-[7px] rounded-lg flex justify-center items-center" onClick={() => toDeleteVente(invoice.id)}>
                                                                <DeleteOutlineOutlined/>
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </td>
                                    </> :  !invoice.is_deleted ? <td className="p-3 border-b border-gray-200 text-sm text-gray-700 whitespace-nowrap">
                                        <button className="bg-[#C4C4C4] p-2 h-full rounded-lg text-white " onClick={() => toDeleteVente(invoice.id)}>
                                            <img src={corbeille} alt="delete" className="w-[19px] h-[19px] max-w-[19px]"/>
                                        </button>
                                    </td> : <td className={realUser.role === "client" ? "border-b border-gray-200" : ""}></td>}

                                </tr>
                            })
                            }
                            {
                                displayBlockAllButton && realUser.role !== "client" ?
                                <tr>
                                    <td  colSpan={10}>

                                    </td>
                                    <td>
                                        <div className="flex justify-center mt-4">
                                            <button><span className="text-[#B3B3B3] underline font-semibold" onClick={checkAllBlockInvoices}>Tous...</span></button>
                                        </div>
                                    </td>
                                </tr> : null
                            }

                            </tbody>
                    }


                </table>
            </div>
            {msgModalOpen && <PopupMessagerieClientOperation closeModal={closeModal} operation={rowToAffect}/>}
            {deleteModalOpen && <PopupDeleteRowVente isLastInvoice={isLastRow} close={closeModalDeleteRow} fetchEarnings={() => fetchEarnings(earnings.per_page, earnings.page)} idToSupp={idToSupp}/>}
            {afficheBlockModale && <PopupBlockTransactions closeModale={close} isLoadingComponent={loadingComponent} block={blockVenteChecked} info="vente"/>}
            {unblockVenteModale && <PopupConfirmUnblock closeModale={close} isLoadingComponent={loadingComponent} unblock={unblockinvoice} info="vente"/>}
            {afficheFecExportModale && <PopupConfirmFecExport closeModale={close}  type="vente"/>}

            {/*</section>*/}
            <Pagination data={earnings} paginate={fetchEarnings}/>

        </React.Fragment>
    )
}

export default VenteTable