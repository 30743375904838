import React, {useEffect, useState, useRef} from 'react'
import withFilterProvider from "../components/FilterProvider";

import DashboardPage from '../pages/dashboard'
import {Switch, Link, Route, BrowserRouter as Router, useLocation} from 'react-router-dom'
import Logo from '../assets/logo.png'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import SearchIcon from '@material-ui/icons/Search';
import {useDispatch, useSelector} from "react-redux";
import MesEncoursPage from "../pages/mesEncours";
import SimusPage from "../pages/mesSimus";
import AdminP from "../pages/adminP";
import MonBbb from "../pages/monBbb";
import RegistrationClient from "../components/Mon BBB/registrationClient";
import InfosClient from "../pages/register";
import MesInformations from "../pages/mesInformations";
import {getUser, logoutUserState, getRealUser} from "../app/user";
import AuthenticatedRoute from "../components/auth/AuthenticatedRoute";
import AddDoc from "../components/Mon BBB/addDoc";
import ExportAffectation from "../pages/ExportAffectation";
import {removeItem, getItem} from "../services/LocaleStorage";
import AddFacture from "../components/Mon BBB/addFacture";
import BBBMenu from '../components/BBBMenu'
import http from '../services/Http'
import {toast, ToastContainer} from "react-toastify";
import AdminN from "../pages/adminN";
import MessagesTableAccessClient from "../pages/messagesTableAccessClient";
import ImportationBancaire from "../components/Admin P/importationBancaire";
import RegistrationAdminN from "../components/Mon BBB/registrationAdminN";
import {
    clearOptional, 
    getBankAccount, 
    getBankAccounts, 
    setDateEnd, 
    setDateStart, 
    setMyBankAccount, 
    updateDate
} from "../app/filters";
import MessagesTableAdminN from "../pages/messagesTableAdminN";
import {CloseSharp} from "@material-ui/icons";
import SearchBar from "../components/searchBar";
import FilterButton from "../components/FilterButton";
import calendarIcon from "../assets/img/calendar.png";
import wallet from "../assets/img/wallet.png";
import help from "../assets/img/help.png";
import tuto from "../assets/img/tuto.png";
import exportArrow from "../assets/img/exportArrow.png"
import importArrow from "../assets/img/importArrow.png"
import menu from "../assets/img/menu.png";
import mailround from '../assets/img/mailround.png';
import notificationIcon from '../assets/img/notificationIcon.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
const WrappedPrivateLayout = ({ setNotAuthenticated }) => {
    return (
        <Router>
            <PrivateLayout setNotAuthenticated={setNotAuthenticated}/>
        </Router>
    )
}

const PrivateLayout = ({ setNotAuthenticated }) => {

    //import relevé bancaire
    const [importModalOpen, setImportModalOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [importVenteModalOpen, setImportVenteModalOpen] = useState(false);
    const [showButtonsDate, setShowButtonsDate] = useState(true);
    const realUser = useSelector(getRealUser)
    const location = useLocation()
    const [open, setOpen] = useState(null);
    const [startDate, setStartDate] = useState(new Date().getFullYear() + "-01-01");
    const [endDate, setEndDate] = useState(new Date().getFullYear() + "-12-31");
    const startDatePickerRef = useRef(null);
    const endDatePickerRef = useRef(null);

    const handleIconClick = (pickerRef) => {
        if (pickerRef.current) {
            pickerRef.current.setOpen(true);
        }
    };

    const closeModal = () => {
        setImportModalOpen(false)
        setImportVenteModalOpen(false)
    }

    const connectBankAccount = async () => {
        const token = getItem("bbbToken")
        let endpoint = '/bank_account/generate-widget?client_id=' + user.id + '&bank_account_id=' + bankAccount.id

        let toastId = toast.info("Redirection vers Linxo en cours...")

        const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        if (response.status === 200) {
            const responseJson = await response.json()
            window.open(responseJson.widget_session.link + '&redirect_url=' + encodeURIComponent(process.env.REACT_APP_URL + '/dashboard?linxo_redirect=true&wait_sync_end=true&consent_per_account=true&bbb_bank=' + bankAccount.id), '_blank')
            toast.dismiss(toastId)
        } else {
            if (response.status === 417) {
                let responseJson = await response.json()
                if (responseJson.message) {
                    toast.update(toastId, { render: responseJson.message, type: toast.TYPE.ERROR, autoClose: 5000 })
                    return
                }
            }
            toast.update(toastId, { render: "Une erreur est survenue, veuillez réessayer plus tard", type: toast.TYPE.ERROR, autoClose: 5000 })

        }


    }

    const manageConnections = async () => {
        const token = getItem("bbbToken")
        let endpoint = '/bank_account/manage_connections?client_id=' + user.id

        let toastId = toast.info("Redirection vers Linxo en cours...")

        const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        const responseJson = await response.json()

        if (response.status === 200) {
            window.open(responseJson.widget_session.manage_link + '&close_url=' + encodeURIComponent(process.env.REACT_APP_URL + '/dashboard?linxo_redirect=true&bbb_bank=' + bankAccount.id), '_blank')
            toast.dismiss(toastId)
        } else {
            if (responseJson.message) {
                toast.error(responseJson.message, { toastId: toastId })
            } else {
                toast.error("Une erreur est survenue, veuillez réessayer plus tard", { toastId: toastId })
            }
        }
    }

    const refreshBankAccount = async () => {
        const token = getItem("bbbToken")
        let endpoint = '/bank_account/sync-transactions?client_id='+ user.id + '&bank_account_id=' + bankAccount.id

        let toastId = toast.info("synchronisation du compte en cours...")

        const response =  await fetch(process.env.REACT_APP_API_URL + endpoint, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        if (response.status === 200) {
        //TODO refresh transactions table
            toast.success("Le compte a été synchronisé avec succès", { toastId: toastId })
            dispatch(clearOptional())
            // window.location.href = '/dashboard'

        } else {
            toast.error("Une erreur est survenue, veuillez réessayer ultérieurement.", { toastId: toastId })
        }

    }
    const bankAccount = useSelector(getBankAccount)
    const bankAccounts = useSelector(getBankAccounts)
    const user = useSelector(getUser)
    const pageTitle = useSelector(state => state.filters.pageTitle)
    const dispatch = useDispatch()
    const [virtualUser, setVirtualUser] = useState(null)

    useEffect(() => {
        dispatch(clearOptional())
    }, [location]);


    const logoutUser = () => {
        // Virtual user does not need log out, closing the window is enough
        if (virtualUser) {
            window.close()
            return
        }

        removeItem('bbbToken')
        setNotAuthenticated()
        dispatch(logoutUserState())
    }

    const menuEnter = () => {
        let menu = document.getElementById('menu-bbb')
        menu.classList.remove('hidden')
    }
    const menuLeave = () => {
        let menu = document.getElementById('menu-bbb')
        menu.classList.add('hidden')

    }
    const MonCompteMouseEnter = () => {
        let monCompte = document.getElementById('mon-compte')
        monCompte.classList.remove('hidden')
    }
    const MonCompteMouseLeave = () => {
        let monCompte = document.getElementById('mon-compte')
        monCompte.classList.add('hidden')
    }

    const homeUrl = () => {
        const usp = new URLSearchParams(window.location.search)
        const userID = usp.get('vusertype')
        const userType = usp.get('vuserid')
        if (userID) {
            return `/?vuserid=${userID}&vusertype=${userType}`
        }
        return "/"
    }

    useEffect(() => {
        const clientID = http.getClientIdFromUrl()
        const clientRole = http.getClientRoleFromUrl()
        if (clientID && clientRole === "client") {
            fetchUser(clientID, '/clients?client_id=')
        }
        if (clientID && clientRole === "admin_n") {
            fetchUser(clientID, '/admins_n?admin_n_id=')
        }
    }, [])

     // Fonction de mise à jour des dates
     const updateDates = (showButtonsDate) => {
        if (showButtonsDate) {
            const startDateElement = document.getElementById("input-period-from");
            const endDateElement = document.getElementById("input-period-to");
    
            const startDateValue = startDateElement.value;
            const startDateValidity = startDateElement.validity.valid;
            const endDateValue = endDateElement.value;
            const endDateValidity = endDateElement.validity.valid;
            
            console.log('dates', startDateValue, endDateValue);
    
            // Convert and reformat dates to 'YYYY-MM-DD'
            const parseDate = (dateStr) => {
                const [day, month, year] = dateStr.split('/');
                return `${year}-${month}-${day}`;
            };
    
            const formattedStartDate = parseDate(startDateValue);
            const formattedEndDate = parseDate(endDateValue);
    
            console.log("update dates", formattedStartDate, startDateValidity, formattedEndDate, endDateValidity);
    
            dispatch(setDateStart({ value: formattedStartDate, validity: startDateValidity }));
            dispatch(setDateEnd({ value: formattedEndDate, validity: endDateValidity }));
        }
    };
    useEffect(() => {
        updateDates(showButtonsDate);
    }, [showButtonsDate]);

    // Fonction de mise à jour des dates pour passer au ButtonFilter
    const handleUpdateDates = () => {
        updateDates(showButtonsDate);
    };

    const fetchUser = (userID, route) => {
        const url = route + userID
        const token = getItem('bbbToken')
        http.get(url, {'Authorization': 'Bearer ' + token})
            .then(data => {
                setVirtualUser(data)
                dispatch(setVirtualUser(data))

            })
            .catch(() => {
                setVirtualUser(null)
            })
    }

    const sendBank = (e) => {
        dispatch(setMyBankAccount(bankAccounts.find(bankA => bankA.id === parseInt(e.target.value))))
    }

    const reindexInvoiceRefs = async () => {
        let token = getItem('bbbToken')
        let toastId = toast.loading("Réindexation des factures en cours...")

        let response = await fetch(process.env.REACT_APP_API_URL + '/invoice/reindex-invoice-ref', {
            method: "POST",
            body: JSON.stringify({ client_id: user.id }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })

        if (response.status === 200) {
            toast.update(toastId, { render: "Réindexation terminée avec succès", type: toast.TYPE.SUCCESS, autoClose: 5000 })
            window.location.reload()
        } else {
            toast.update(toastId, { render: "Une erreur est survenue, veuillez réessayer plus tard", type: toast.TYPE.ERROR, autoClose: 5000 })
        }

    }

    return (
            <div className="relative">
                {/* top bar*/}
                <ToastContainer autoClose={3000} pauseOnHover={false}/>

                <section className="sticky bg-white top-0 flex justify-between w-full z-50 top-menu h-[6.4rem] sidebar:w-[calc(100%-21.4rem)]">

                    <div className="pl-[3.3rem] flex">
                        <div className="flex flex-col relative pt-[2.1rem]" onMouseEnter={menuEnter} onMouseLeave={menuLeave}>
                            {/* Menu btn */}
                            <div className="flex items-center cursor-pointer">
                                <button className="m-none side-menu mr-4" onClick={()=> {setOpen("menu")}}>
                                    <img src={menu} className="w-full" alt="menu"/>
                                </button>
                                <SearchIcon onClick={()=> {setOpen("sidebar")}}/>
                                <span className="text-[2rem] text-gray-900 font-bold pl-[3.3rem] inline-block align-bottom">{pageTitle}</span>
                            </div>

                            {/* Navigationn menu */}
                            <div className="hidden sidebar:block">
                                <div id="menu-bbb" className="absolute bg-white rounded-xl shadow-lg hidden top-full over overflow-y-hidden">
                                    <ul>
                                        <BBBMenu user={user}/>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Message icon et notification */}
                    <div className="top-0 ml-auto mr-[5.3rem] relative pt-[2.1rem] flex items-center">
                        <div className="mr-5">
                            <img src={notificationIcon} className="img-notification" alt="img-notification"/>
                        </div>
                        <div>
                            <img src={mailround} className="img-mail" alt="img-mail"/>
                        </div>
                    </div>

                    {/* Right box */}
                    <div className="flex account top-0 relative pt-[2.1rem]" onMouseOver={MonCompteMouseEnter}
                         onMouseLeave={MonCompteMouseLeave}>        
                        {/* User name and role */}
                        <div className="flex flex-col ml-auto mb-1 mr-1">
                            <p className="text-base font-bold text-gray-700 w-max">{user.display_name}</p>
                            <p className="text-xs text-gray-500">{user.role}</p>
                        </div>


                        <div id="mon-compte" className="absolute bg-white rounded-xl shadow-lg hidden top-full">
                            <ul>
                                {/* Its component does not exist in Figma yet, so we've to hide the button temporarily */}
                                {/* <li><Link to='/reset-pass' title="Gérez votre activité ">Changer le mdp</Link></li> */}
                                <li><Link to='' className="text-red-500" onClick={logoutUser}>Logout</Link></li>
                            </ul>
                        </div>
                        {/* User avatar */}
                        <div className="w-1/3 block">
                            {/* User avatar */}
                            <div className="p-2 bg-gray-300 w-12 h-12 rounded-xl"/>
                        </div>
                    </div>
                </section>
                <section className="mobile-navbar">
                    <div className="btn">
                        <a href="/"><HomeIcon/></a>
                        <a href="#"><DescriptionIcon/></a>
                        <a href="#"><SearchIcon/></a>
                    </div>
                </section>

                {/*overlay des sidebars*/}
                <div className={`${open !== null ? "" : "hidden"} z-[10000000] sidebar:hidden fixed top-0 left-0 w-full h-full bg-black opacity-20`}/>

                <div className="w-full">
                    {/* Side bar ici */}
                    <section className={`${open === "sidebar" ?  "translate-x-0 rounded-r-3xl shadow-2xl sidebar:shadow-none" : "-translate-x-full shadow-none sidebar:translate-x-0"} z-[10000001] pl-[2.7rem] text-base h-screen side-menu fixed bg-sidebar w-[46.5rem] sidebar:w-[21.4rem] sidebar:transition-none transition-all duration-200 ease-in-out`}>

                        {/* Menu links */}
                        <div className="flex justify-between sidebar:pb-[4.1rem] pb-2 pt-[2.1rem] relative mb-[25px] sidebar:mb-0" >
                            {/*<div className="absolute right-0 top-0 transform translate-x-[calc(100%+0.2rem)] translate-y-full bg-lilaFonce text-white font-bold rounded-lg px-4 py-2 sidebar:hidden mt-[20.8px]" onClick={() => open === null ? setOpen("sidebar") : setOpen(null)}><img src={arrowRight} alt="expendMenu"/></div>*/}
                            {/* Icon */}
                            <a href={homeUrl()} >
                                <img src={Logo} className="w-[17.5rem] " alt="logo"/>
                            </a>
                            <div className="pr-4 cursor-pointer sidebar:hidden" onClick={() => setOpen(null)} >
                                <CloseSharp />
                            </div>
                        </div>

                        <div className=" flex gap-8 sidebar:flex-col">
                            <div className="w-[17.5rem]">
                                <SearchBar/>
                            </div>
                            <div className="w-[9.3rem] sidebar:w-[17.5rem] text-[14px] sidebar:text-base sidebar:normal rounded-r-xl sidebar:mt-2 pick-input">
                                <p className="hidden sidebar:block text-black font-bold text-base">Menu</p>
                                <div className="w-full flex items-center sidebar:items-center sidebar:mt-[1.2rem] h-[2rem]">
                                    <img className="w-[25px] sidebar:w-[30px]" src={calendarIcon} onClick={() => showButtonsDate ? setShowButtonsDate(false) : setShowButtonsDate(true)} alt="calendar"/>
                                    {/*<DateRangeIcon onClick={() => showButtonsDate ? setShowButtonsDate(false) : setShowButtonsDate(true)}/>*/}
                                    <h2 className="pl-[0.9rem] sidebar:text-base font-semibold">Période affichée</h2>
                                </div>
                                {/*mes dates*/}
                                {showButtonsDate ?
                                    <div className="flex justify-between w-[9.3rem] sidebar:w-[17.5rem] mt-[1.5rem] sidebar:mt-[0.9rem] periode-aff">
                                        <div className="px-[0.9rem] flex justify-center w-[48%] rounded-lg border h-[2.3rem] sidebar:py-1 sidebar:h-[2.7rem] items-center">
                                            <div className="flex text-xs items-center dates-filtre font-bold relative w-full">
                                                <span className="uppercase mr-1">Du</span>
                                                <DatePicker
                                                    id="input-period-from"
                                                    selected={startDate}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={(date) => setStartDate(date)}
                                                    className="bg-none-input custom-calendar text-xs w-full pr-[0.5rem]" 
                                                    dateFormat="dd/MM/yyyy"
                                                    minDate={new Date('1990-01-01')}
                                                    maxDate={new Date('2300-01-01')}
                                                    ref={startDatePickerRef}
                                                />
                                                <ArrowDropDownIcon
                                                    className="custom-calendar-icon absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                                                    onClick={() => handleIconClick(startDatePickerRef)}
                                                />
                                            </div>
                                        </div>

                                        <div className="px-[0.9rem] flex justify-center w-[48%] mt-[1.2rem] sidebar:mt-0 rounded-lg border h-[2.3rem] sidebar:py-1 sidebar:h-[2.7rem] items-center">
                                            <div className="flex text-xs items-center dates-filtre font-bold relative w-full">
                                                <span className="uppercase mr-1">Au</span>
                                                <DatePicker
                                                    id="input-period-to"
                                                    selected={endDate}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={(date) => setEndDate(date)}
                                                    className="bg-none-input custom-calendar text-xs w-full pr-[0.5rem]"
                                                    dateFormat="dd/MM/yyyy"
                                                    minDate={new Date('1990-01-01')}
                                                    maxDate={new Date('2300-12-31')}
                                                    ref={endDatePickerRef}
                                                />
                                                <ArrowDropDownIcon
                                                    className="custom-calendar-icon absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                                                    onClick={() => handleIconClick(endDatePickerRef)}
                                                />
                                            </div>
                                        </div>
                                    </div>:

                                    <form className="rounded-r-xl mt-[0.9rem] w-full flex relative input-field">
                                        <input type="month" name="periodeaffiche"/>
                                        <ArrowDropDownIcon size="small" className="pointer-events-none"/>
                                    </form>
                                }
                                <FilterButton onUpdateDates={handleUpdateDates} />
                            </div>
                            {
                                user.role === "client" &&
                                <React.Fragment>
                                    {
                                        bankAccounts.length !== 0 ? <div className=" sidebar:w-[17.5rem] sidebar:mt-[0rem] rounded-r-xl pick-input">
                                            <div className="w-full flex items-start sidebar:items-center h-[1rem]">
                                                <img className="w-[25px] sidebar:w-[30px]" src={wallet} alt="wallet"/>

                                                <h2 className="pl-[0.9rem] text-[14px] sidebar:text-[16px] font-semibold">Compte bancaire</h2>
                                            </div>
                                            <form className="rounded-r-xl mt-[1.5rem] sidebar:mt-[0.9rem] sidebar:w-full flex relative input-field h-[2.3rem] w-[202px]">
                                                <select 
                                                    className="rounded-lg p-2 w-3/6 mx-auto text-[12px] sidebar:text-[14px] "
                                                    onChange={(e) => sendBank(e)}
                                                >
                                                    {bankAccounts.map((account) => {
                                                        return <option key={account.id} value={account.id}>{account.name}</option>
                                                    })}
                                                </select>
                                                <ArrowDropDownIcon size="small" className="pointer-events-none"/>
                                            </form>
                                            {
                                                bankAccount.is_connected ?
                                                <div className="flex justify-between pt-4">
                                                    <div className="flex items-center">
                                                        <FiberManualRecordIcon className="sidebar:text-4xl text-2xl text-green-400 mr-[0.5rem]" style={{ fontSize: 'inherit' }} />
                                                        <span className="text-xs font-semibold">Compte connecté</span>
                                                    </div>
                                                    {realUser.role === "client" ? 
                                                        <div className="flex items-center">
                                                            <button className="bg-grisClaire text-xs p-2 rounded-lg" onClick={refreshBankAccount}>Actualiser</button>
                                                        </div> 
                                                    : null}
                                                </div>
                                                :
                                                <div className="flex w-full justify-between pt-4">
                                                    <div className="p-2 flex items-center">
                                                        <FiberManualRecordIcon className="sidebar:text-4xl text-2xl text-redCorail mr-[0.5rem]" style={{ fontSize: 'inherit' }} />
                                                        <span className="text-xs font-semibold">Compte non connecté</span>
                                                    </div>
                                                    {realUser.role === "client" ?
                                                        <div className="flex items-center mr-[20px] sidebar:mr-0">
                                                            <button className="bg-grisClaire text-xs p-2 rounded-lg" onClick={connectBankAccount}>connecter</button>
                                                        </div> 
                                                    : null}
                                                </div>
                                            }
                                            <button className="text-xs mt-2" onClick={manageConnections}>Gérer mes connections</button>
                                        </div>
                                    : 
                                        null
                                    }
                                </React.Fragment>
                            }
                        </div>

                        <div className="pt-[0.5rem] relative" >
                            <a href="/" className=" rounded-r-xl h-[1.9rem] w-full flex items-center mt-[1.2rem] sidebar:mt-[1.9rem]">
                                <img src={help} alt="help" className="h-[25px] sidebar:h-[30px]"/>
                                <div className="w-full m-auto pl-[1.5rem]">
                                    <p className="w-3/4 font-semibold">Aide</p>
                                </div>
                            </a>
                            <a href="/" className="rounded-r-xl h-[1.9rem] w-full flex items-center mt-[1.2rem] sidebar:mt-[1.9rem]">
                                <img src={tuto} alt="tuto" className="h-[25px] sidebar:h-[30px]"/>
                                <div className="w-full m-auto pl-[1.5rem]">
                                    <p className="w-3/4 font-semibold">Tuto mon suivi compta</p>
                                </div>
                            </a>
                        </div>

                        <div className="rounded-r-xl h-[1.9rem] w-full flex items-center mt-[1.2rem] sidebar:mt-[1.9rem]">
                            <img src={exportArrow} alt="export" className="h-[25px] sidebar:h-[30px]"/>
                            <div className="w-full m-auto pl-[1.5rem]">
                                <p className="w-3/4 font-semibold" onClick={() => {
                                    setImportVenteModalOpen(true)
                                }}>Export
                                </p>
                            </div>
                        </div>

                        {realUser.role === "client" || user.role === "client" ?
                            <div className="rounded-r-xl h-[1.9rem] w-full flex items-center mt-[1.2rem] sidebar:mt-[1.9rem]">
                                <img className="rotate-180 -scale-x-1 h-[25px] sidebar:h-[30px]" src={importArrow} alt="import"/>
                                <div className="w-full m-auto pl-[1.5rem]">
                                    {/*<Link to="/affectation-operation"><p className="pl-3 w-3/4">Export</p></Link>*/}
                                    <p className="w-3/4 cursor-pointer font-semibold" onClick={() => {
                                        setImportModalOpen(true)
                                    }}>Import
                                    </p>
                                </div>
                            </div> : null
                        }

                        {realUser.role === "admin_p" && user.role === "client" ?
                            <button className="mt-4 px-2 py-1 bg-red-100 rounded-lg text-xs" type="button" onClick={reindexInvoiceRefs}>Réindexer les références</button> : null}

                        <div className="mt-10 p-4 pr-0" style={{fontSize: '10px'}}>
                            <p className="font-bold leading-4">Blabliblou Invoicing Admin Dashboard</p>
                            <p className=" leading-4">© 2020 All Rights Reserved</p>
                        </div>
                    </section>

                    {/* menu burger */}
                    <section className={`${open === "menu" ? "translate-x-0 rounded-r-3xl shadow-2xl sidebar:shadow-none" : "-translate-x-full shadow-none sidebar:translate-x-0"} z-[10000001] sidebar:hidden pl-[2.7rem] text-base h-screen side-menu fixed bg-sidebar w-[25rem] sidebar:w-[21.4rem] sidebar:transition-none transition-all duration-200 ease-in-out flex flex-col justify-between`}>

                       <div>
                           <div className="flex justify-between sidebar:pb-[4.1rem] pb-2 pt-[2.1rem] relative" >
                               {/*<div className="absolute right-0 top-0 transform translate-x-[calc(100%+0.2rem)] translate-y-full bg-lilaFonce text-white font-bold rounded-lg px-4 py-2 sidebar:hidden mt-[20.8px]" onClick={() => open === null ? setOpen("menu") : setOpen(null)}><img src={arrowRight} alt="expendMenu"/></div>*/}
                               {/* Icon */}
                               <a href={homeUrl()} >
                                   <img src={Logo} className="sidebar:w-[17.5rem] w-[10rem]" alt="logo"/>
                               </a>
                               <div className="pr-4 cursor-pointer" onClick={() => setOpen(null)} >
                                   <CloseSharp />
                               </div>
                           </div>
                           <div className="text-2xl mt-[2.8rem]">
                               <ul className="flex flex-col gap-4 font-bold text-[#707579]">
                                   <BBBMenu user={user}/>
                               </ul>
                           </div>
                       </div>

                        <div className="pt-4 pb-12" style={{fontSize: '10px'}}>
                            <p className="font-bold leading-4">Blabliblou Invoicing Admin Dashboard</p>
                            <p className=" leading-4">© 2020 All Rights Reserved</p>
                        </div>
                    </section>

                    {/*content right*/}
                    <div className="w-full sidebar:w-[calc(100%-21.4rem)] sidebar:ml-[21.4rem]">
                        {
                            bankAccount === null && user.role === "client" ?
                                <Switch><Route roles={['client', 'admin_p', 'admin_n']} path="/" component={MonBbb}/></Switch>

                                : user.role === "client" ? <React.Fragment>
                                        <Switch><Route exact path="/"
                                                       component={DashboardPage}/>
                                            <AuthenticatedRoute path="/dashboard" component={DashboardPage}/>
                                            <Route exact path="/mes-documents" component={MesEncoursPage}/>
                                            <Route exact path="/mes-simus" component={SimusPage}/>
                                            {/*gestion utilisateur Admin N*/}
                                            <AuthenticatedRoute roles={['client', 'admin_p', 'admin_n']} path="/mon-bbb" component={MonBbb}/>
                                            <Route exact path="/mes-infos" component={InfosClient}/>
                                            <Route exact path="/mes-infos-client" component={MesInformations}/>
                                            <AuthenticatedRoute roles={['admin_n', 'client', 'admin_p']} path="/access-messagerie-client" component={MessagesTableAccessClient}/>

                                            <Route exact path="/affectation-operation" component={ExportAffectation}/>
                                            <Route exact path="/affect" component={ExportAffectation}/></Switch>

                                    </React.Fragment>
                                    : <React.Fragment>
                                        <Switch><Route exact path="/"
                                                       component={user.role === "admin_p" ? AdminP : AdminN}/>
                                            <AuthenticatedRoute roles={['admin_p']} path="/admin-p" component={AdminP}/>
                                            {/*gestion utilisateur Admin N*/}
                                            <Route path="/admin-n" component={AdminN}/>
                                            <AuthenticatedRoute roles={['client', 'admin_p', 'admin_n']} path="/mon-bbb" component={MonBbb}/>
                                            {/*<Route exact path="/mes-infos" component={InfosClient}/>*/}
                                            {/*<Route exact path="/mes-infos-client" component={MesInformations}/>*/}
                                            <Route exact path="/register-client" component={RegistrationClient}/>
                                            {/*<Route exact path="/register-client-old" component={RegistrationClientOld}/>*/}

                                            {user.role === "admin_p" && <Route exact path="/register-admin-n" component={RegistrationAdminN}/>}
                                            <Route exact path="/add-doc" component={AddDoc}/>
                                            <Route exact path="/add-facture" component={AddFacture}/>
                                            {/*Messagerie admin N*/}
                                            <AuthenticatedRoute roles={['admin_n', 'client', 'admin_p']} path="/access-messagerie-client" component={MessagesTableAccessClient}/>
                                            <AuthenticatedRoute roles={['admin_n', 'admin_p']} path="/messagerie-admin-n" component={MessagesTableAdminN}/>

                                            <Route exact path="/affectation-operation" component={ExportAffectation}/>
                                            <Route exact path="/affect" component={ExportAffectation}/></Switch>

                                    </React.Fragment>
                        }
                    </div>
                </div>
                {importModalOpen && <ImportationBancaire user={user} closeModal={closeModal} fetchOperationsBancaires={()=> dispatch(updateDate())}/>}

            </div>
    )
    // }
}

// export default WrappedPrivateLayout
export default withFilterProvider(WrappedPrivateLayout)