import {createSlice} from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
    name: 'loader',
    initialState: {
        isActive: false,
        counter: 0
    },
    reducers: {
        setLoaderSubscribe(state) {
            state.counter++
            state.isActive = true
        },
        setLoaderUnsubscribe(state) {
            state.counter === 0 ? state.counter = 0 : state.counter--
            if (state.counter === 0) {
                state.isActive = false
            }
        }
    },
})

export const {setLoaderUnsubscribe, setLoaderSubscribe} = loaderSlice.actions
export default loaderSlice.reducer

export function getLoader(state) {
    return state.loader.isActive
}
