import React, {useEffect} from 'react'
import {getItem} from "../../services/LocaleStorage";
import {useState} from 'react';
import StatePanel from './StatePanel.js';
import {useSelector} from "react-redux";
import {getUser} from "../../app/user";
import {getBankAccount, getDatesFilter} from "../../app/filters";

// eslint-disable-next-line no-unused-vars
const GeneralStats = ({setSelectState, selectedState, ...rest}) => {
    // eslint-disable-next-line no-unused-vars
    const [panneauIndicat, setPanneauIndicat] = useState({});

    const frontUser = useSelector(getUser)
    const dateFilter = useSelector(getDatesFilter)
    const bankAccount = useSelector(getBankAccount)

    useEffect(() => {
        if (dateFilter[0] !== null && dateFilter[1] !== null && bankAccount !== null) {
            fetchIndicat();
        }
    }, []);

    const formatNumber = (number) => {
        if (number === null || number === undefined) {
            return "--";
        }
    
        return new Intl.NumberFormat('fr-FR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true
        }).format(number);
    };

    const [data, setData] = useState({
        treso_period: {
            title: "Tréso période",
            total_amount: "--",
            bgColor: "bg-dashboard_tresoperiod",
            nState: 1,
            data: [
                {
                    title: "Tréso prévue",
                    amount: "--",
                },
                {
                    title: "Solde du jour",
                    amount: "--",
                },
            ]
        },
        resultas_ht: {
            title: "Résultat HT",
            total_amount:  "--",
            bgColor: "bg-dashboard_resultasht",
            nState: 2,
            data: [
                {
                    title: "Résultat HT estimé",
                    amount:  "--",
                },
                {
                    title: "Résultat HT prévu",
                    amount:  "--",
                },
                {
                    title: "Résultat HT n-1",
                    amount: "--",
                },
                {
                    title: "Marge commerciale",
                    amount: "--",
                },
            ]
        },
        ca_encaisse: {
            title: "CA Encaisse HT",
            total_amount: "--",
            bgColor: "bg-dashboard_encaisseht",
            nState: 3,
            data: [
                {
                    title: "CA HT estimé",
                    amount: "--",
                },
                {
                    title: "CA HT prévu",
                    amount: "--",
                },
                {
                    title: "CA HT n-1",
                    amount: "--",
                },
            ]
        },
        frais_ht: {
            title: "Dépenses HT",
            total_amount: "--",
            bgColor: "bg-dashboard_fraisht",
            nState: 4,
            data: [
                {
                    title: "Dépenses HT estimées",
                    amount: "--",
                },
                {
                    title: "Dépenses HT prévues",
                    amount: "--",
                },
                {
                    title: "Dépenses HT n-1",
                    amount: "--",
                },
            ]
        },
        loadingComp: true
    })

    const fetchIndicat = () => {
        console.log('calling fetch StatsAndGraphs -> GeneralStats');
        const token = getItem('bbbToken')
        fetch(process.env.REACT_APP_API_URL + '/stats/dashboard?start_at=' + dateFilter[0] + '&end_at=' + dateFilter[1] + '&client_id=' + frontUser.id + '&bank_id=' + bankAccount.id, {
            method: 'GET',
            requirements: token,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        console.log('GeneralStats', json);
                        setPanneauIndicat(json)
                        setData({
                            treso_period: {
                                title: "Tréso période",
                                total_amount: json.treasury.current !== null ? formatNumber(json.treasury.current) : "--",
                                bgColor: "bg-dashboard_tresoperiod",
                                nState: 1,
                                data: [
                                    {
                                        title: "Treso prévue",
                                        // amount: json.treasury.expected !== null ? formatNumber(json.treasury.expected) : "--"
                                        amount: "--"
                                    },
                                    {
                                        title: "Solde du jour",
                                        amount: json.treasury.today_balance !== null ? formatNumber(json.treasury.today_balance) : "--"
                                    },
                                ]
                            },
                            resultas_ht: {
                                title: "Résultat HT",
                                total_amount: (json.ca_encaissee - json.frais) !== null ? formatNumber(json.ca_encaissee - json.frais) : "--",
                                bgColor: "bg-dashboard_resultasht",
                                nState: 2,
                                data: [
                                    {
                                        title: "Résultat HT estimé",
                                        amount: json.ca_estimee !== null && json.frais_estimee !== null ? formatNumber(json.ca_estimee - json.frais_estimee) : "--"
                                    },
                                    {
                                        title: "Résultat HT prévu",
                                        // amount: json.ca_prevu !== null && json.frais_prevu !== null ? formatNumber(json.ca_prevu - json.frais_prevu) : "--"
                                        amount: "--"
                                    },
                                    {
                                        title: "Résultat HT n-1",
                                        // amount: json.previous_ca !== null && json.previous_frais !== null ? formatNumber(json.previous_ca - json.previous_frais) : "--"
                                        amount: "--"
                                    },
                                    {
                                        title: "Marge commerciale",
                                        // amount: json.marge_commerciale !== null ? formatNumber(json.marge_commerciale) : "--"
                                        amount: "--"
                                    },
                                ]
                            },
                            ca_encaisse: {
                                title: "CA Encaisse HT",
                                total_amount: json.ca_encaissee !== null ? formatNumber(json.ca_encaissee) : "--",
                                bgColor: "bg-dashboard_encaisseht",
                                nState: 3,
                                data: [
                                    {
                                        title: "CA HT estimé",
                                        amount: json.ca_estimee !== null ? formatNumber(json.ca_estimee) : "--"
                                    },
                                    {
                                        title: "CA HT prévu",
                                        // amount: json.ca_prevu !== null ? formatNumber(json.ca_prevu) : "--"
                                        amount: "--"
                                    },
                                    {
                                        title: "CA HT n-1",
                                        // amount: json.previous_ca !== null ? formatNumber(json.previous_ca) : "--"
                                        amount: "--"
                                    },
                                ]
                            },
                            frais_ht: {
                                title: "Dépenses HT",
                                total_amount: json.frais !== null ? formatNumber(json.frais) : "--",
                                bgColor: "bg-dashboard_fraisht",
                                nState: 4,
                                data: [
                                    {
                                        title: "Dépenses HT estimées",
                                        amount: json.frais_estimee !== null ? formatNumber(json.frais_estimee) : "--"
                                    },
                                    {
                                        title: "Dépenses HT prévues",
                                        // amount: json.frais_prevu !== null ? formatNumber(json.frais_prevu) : "--"
                                        amount: "--"
                                    },
                                    {
                                        title: "Dépenses HT n-1",
                                        // amount: json.previous_frais !== null ? formatNumber(json.previous_frais) : "--"
                                        amount: "--"
                                    },
                                ]
                            },
                            loadingComp: false
                        })                        
                    })
                } else {
                    setData({
                        treso_period: {
                            title: "Treso période",
                            total_amount: "--",
                            bgColor: "bg-dashboard_tresoperiod",
                            nState: 1,
                            data: [
                                {
                                    title: "Treso prévue",
                                    amount: "--",
                                },
                                {
                                    title: "Solde du jour",
                                    amount: "--",
                                },
                            ]
                        },
                        resultas_ht: {
                            title: "Résultat HT",
                            total_amount: "--",
                            bgColor: "bg-dashboard_resultasht",
                            nState: 2,
                            data: [
                                {
                                    title: "Résultat HT estimé",
                                    amount: "--",
                                },
                                {
                                    title: "Résultat HT prévu",
                                    amount: "--",
                                },
                                {
                                    title: "Résultat HT n-1",
                                    amount: "--",
                                },
                                {
                                    title: "Marge commerciale",
                                    amount: "--",
                                },
                            ]
                        },
                        ca_encaisse: {
                            title: "CA Encaisse HT",
                            total_amount: "--",
                            bgColor: "bg-dashboard_encaisseht",
                            nState: 3,
                            data: [
                                {
                                    title: "CA HT estimé",
                                    amount: "--",
                                },
                                {
                                    title: "CA HT prévu",
                                    amount: "--",
                                },
                                {
                                    title: "CA HT n-1",
                                    amount: "--",
                                },
                            ]
                        },
                        frais_ht: {
                            title: "Dépenses HT",
                            total_amount: "--",
                            bgColor: "bg-dashboard_fraisht",
                            nState: 4,
                            data: [
                                {
                                    title: "Dépenses HT estimées",
                                    amount: "--",
                                },
                                {
                                    title: "Dépenses HT prévues",
                                    amount: "--",
                                },
                                {
                                    title: "Dépenses HT n-1",
                                    amount: "--",
                                },
                            ]
                        },
                        loadingComp: false
                    })
                }
            })
    }

    return (
        <section className="w-full general-stats pt-[4.1rem] grid grid-cols-2 gap-8 tabletteView:grid-cols-4">
            <StatePanel {...data.treso_period} setSelectState={setSelectState} selectedState={selectedState} loadingComponent={data.loadingComp}/>
            <StatePanel {...data.resultas_ht} setSelectState={setSelectState} selectedState={selectedState} loadingComponent={data.loadingComp}/>
            <StatePanel {...data.ca_encaisse} setSelectState={setSelectState} selectedState={selectedState} loadingComponent={data.loadingComp}/>
            <StatePanel {...data.frais_ht} setSelectState={setSelectState} selectedState={selectedState} loadingComponent={data.loadingComp}/>
        </section>
    )
}

export default GeneralStats