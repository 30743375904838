import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import './assets/style/main.css';
// import './assets/style/dashboard.css'
import PublicLayout from "./layout/public";
import PrivateLayout from "./layout/private";
import Auth from "./context/Auth";
import {hasAuthenticated} from "./services/AuthApi";
import {addItem, getItem} from "./services/LocaleStorage";
import {useDispatch, useSelector, connect} from "react-redux";
import {getUser, isVirtualConnexion, logoutVirtualUser, setUser, setVirtualUser} from "./app/user";
import {BrowserRouter as Router, useLocation} from 'react-router-dom'
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {PulseLoader} from "react-spinners";
import Loader from "./components/Loader";
import http from "./services/Http"
import {getLoader, setLoaderSubscribe, setLoaderUnsubscribe} from "./app/loader";
import {setBankAccounts, setMyBankAccount} from "./app/filters";
import StaticPublic from './layout/StaticPublic';


const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(hasAuthenticated())
    const [path, setPath] = useState(null);
    const dispatch = useDispatch()
    const loader = useSelector(getLoader)

    const [isVirtualUserReady, setIsVirtualUserReady] = useState(false);
    const [isBanksAccountsReady, setIsBanksAccountsReady] = useState(false);

    const notAuthenticated = () => {
        setIsAuthenticated(false)
    }
    const brouette = useSelector(state => state.user)
    const user = useSelector(state => state.user.currentUser)
    const frontUser = useSelector(getUser)
    useEffect(() => {
        if (isAuthenticated) {
            //récupère token
            const token = getItem('bbbToken')
            // Passe le token dans une requete fetch pour que l'api renvoie l'objet user

            fetch(process.env.REACT_APP_API_URL + '/user', {
                method: 'GET',
                requirements: token,
                headers: {
                    'Content-Type': 'application/json',
                    //Toutes requêtes nécessitant d'être connecté (isGranted Symfony) doivent contenir cette authorisation
                    'Authorization': 'Bearer ' + token
                },
                credentials: 'same-origin',
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
            })
                .then(response => response.json())
                .then(response => {
                    // Dispatch sert à utiliser le reduceur (setUser)
                    dispatch(setUser(response))
                })
                .catch(e => {})

            if (http.getClientIdFromUrl()) {
                const route = http.getClientRoleFromUrl() === "client" ? '/clients?include[]=profile&client_id=' : '/admins_n?include[]=profile&admin_n_id='
                const url = route + http.getClientIdFromUrl()
                const token = getItem('bbbToken')
                http.get(url, {'Authorization': 'Bearer ' + token})
                    .then(data => {
                        dispatch(setVirtualUser(data))
                        setIsVirtualUserReady(true)
                    })
                    .catch(err => {
                        dispatch(logoutVirtualUser())
                    })
            } else {
                setIsVirtualUserReady(true)
            }
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isVirtualUserReady && frontUser.role === "client") {
            //Récupère les comptes bancaire utilisateur
            const url = '/bank_accounts?client_id=' + frontUser.id
            const token = getItem('bbbToken')
            http.get(url, {'Authorization': 'Bearer ' + token})
                .then(response => {
                    if(!("No Content" in response)) {
                        dispatch(setMyBankAccount(response[0]))
                        dispatch(setBankAccounts(response))
                    }
                    setIsBanksAccountsReady(true)
                })
                .catch(err => {
                    console.log(err)
                })
        } else if (frontUser.role !== 'client' && isVirtualUserReady && user.id) {
            setIsBanksAccountsReady(true)
        }

    }, [isVirtualUserReady, user]);

    useEffect(() => {
        if (window.location.pathname) {
            setPath(window.location.pathname);
        }
    }, []);

    /* 
        Reson behind the render manipulation below: Current public and private layouts are separate logic wise,
        and we needed to display the static pages for logged in and non-logged in users at the same time
    */
    const staticPaths = [
        '/cgv',
        '/CGV',
        '/confidentialite',
    ];

    let layout;
    if (staticPaths.includes(path)) {
        layout = <StaticPublic />;
    } else {
        // lorsque 'isAuthenticated' change de valeur, App est re-rendu ce qui re-test le if l.17 et bascule l'utilisateur dans le bon layout
        layout = <Auth.Provider value={{isAuthenticated, setIsAuthenticated}}>
            {
                //Afficher la bonne page d'accueil selon l'utilisateur
                isAuthenticated ? 
                    user.id && isVirtualUserReady && isBanksAccountsReady ? 
                        <PrivateLayout setNotAuthenticated={notAuthenticated} /> 
                    :
                        <Loader active={true}/>
                : <PublicLayout/>
            }
            <Loader active={loader}/>
        </Auth.Provider>
    }

    return (
        layout
    )
}

export default App;
