import DoneIcon from "@material-ui/icons/Done";
import React, {useEffect, useState} from "react";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import {useSelector} from "react-redux";
import RegistrationClient from "../Mon BBB/registrationClient";
import {Link} from "react-router-dom";
import {getUser} from "../../app/user";
import Loader from "../../components/Loader";
import nomail from '../../assets/img/nomail.png'
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Pagination from "../Pagination";
import PopupRegistrationAdmin from "./PopupRegistrationAdmin";
import {PulseLoader} from "react-spinners";
import PopupMessagerieClientOperation from "../Admin N/Messagerie/PopupMessagerieClientOperation";
import PopupMessageWithAdminN from "./Messagerie/PopupMessageWithAdminN";
import {DeleteOutlineOutlined, Search} from "@material-ui/icons";

const ListeAdminN = ({dataAdminN, loading, loadingComp, fetchAdmins}) => {
    const user = useSelector(getUser)
    const [openPopupRegistrationAdminN, setOpenPopupRegistrationAdminN] = useState(false);
    const [msgModalOpen, setMsgModalOpen] = useState(false);
    const [conversation, setConversation] = useState(null);
    const [correspondant, setCorrespondant] = useState(null);

    const close = () => {
        setOpenPopupRegistrationAdminN(false)
        setMsgModalOpen(false);
        setConversation(null)
        setCorrespondant(null)
    }
    const openEspaceClient = (e, adminN) => {
        e.stopPropagation();
        window.open("/?vusertype=" + adminN.role + "&vuserid=" + adminN.id, '_blank')
    }


    return (
        <React.Fragment>
            <section className="mt-28">
                <Loader active={loading}/>
                <div className="relative">
                    <div className="overflow-auto rounded-lg w-full">
                        <table className="w-full">
                            <thead className="bgPurpuleCustom text-white">
                            <tr>
                                <th className="w-12 p-3 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Date création<ArrowDropDownIcon/> <ArrowDropUpIcon/></th>
                                <th className="w-12 p-3 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Nom Admin N</th>
                                <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-left">Message en attente</th>
                                <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-center">Message Admin P <ArrowDropDownIcon/> <ArrowDropUpIcon/></th>
                                <th className="p-4 text-sm font-semibold tracking-wide whitespace-nowrap text-center">Connexion à l'espace client</th>

                            </tr>

                            </thead>
                            {
                                loadingComp ?
                                    <tbody>
                                    <tr>
                                        <td className="p-0" colSpan="3">
                                            <PulseLoader
                                                color={'purple'} size={10}/>
                                        </td>
                                    </tr>
                                    </tbody> :
                                    dataAdminN === null || dataAdminN.length === 0 ?
                                        <tbody>
                                        <tr>
                                            <td className="p-4" colSpan="3">Vous n'avez aucun admin</td>
                                        </tr>
                                        </tbody>
                                        :
                                        <tbody className="divide-y divide-gray-200">
                                        {dataAdminN?.map((adminN, index) => {
                                            return <tr key={adminN.id} className={` ${index % 2 === 0 ? "bg-grisbleu" : ""}`} /* onClick={() => {
                                                setMsgModalOpen(true);
                                                setConversation(adminN.conversation);
                                                setCorrespondant(adminN);
                                            }} */>
                                                <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">{adminN.created_at}</td>
                                                <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">{adminN.display_name}</td>
                                                <td className="p-3 text-sm text-gray-700 whitespace-nowrap text-left">-</td>
                                                <td className="p-3 text-sm text-gray-700">
                                                    <div className='mail-container flex justify-center'>
                                                        <img src={nomail} className="img-mailround" alt="" />
                                                        {
                                                            adminN.conversation && parseInt(adminN.conversation.unread_messages_count) > 0 ?
                                                                <span className='mail-badge' style={{background: '#ff9d41'}}>{adminN.conversation ? adminN.conversation.unread_messages_count : ''}</span>
                                                                : ''
                                                        }
                                                    </div>
                                                </td>
                                                <td className="p-3 text-sm text-gray-700 flex justify-center">
                                                    <button type="button" className="bg-lilaFonce p-3 rounded-lg text-white" onClick={(e) => {
                                                        openEspaceClient(e, adminN)
                                                    }}><ExitToAppOutlinedIcon/></button>
                                                </td>



                                            </tr>
                                        })
                                        }
                                        </tbody>
                            }
                        </table>
                    </div>
                    {user.role === "admin_p" ?
                        <button className="btn-add"
                                onClick={() => {
                                    setOpenPopupRegistrationAdminN(true)
                                }}>+</button> : ""
                    }
                </div>

                {openPopupRegistrationAdminN && <PopupRegistrationAdmin close={close}/>}

            </section>
            <Pagination data={dataAdminN} paginate={fetchAdmins}/>
            {msgModalOpen && <PopupMessageWithAdminN conversation={conversation} correspondant={correspondant} closeModal={close}/>}
        </React.Fragment>

    )
}
export default ListeAdminN