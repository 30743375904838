import React, {useEffect, useState} from 'react'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HelpIcon from "@material-ui/icons/Help";
import {useDispatch, useSelector} from "react-redux";
import {getRealUser, getUser} from "../../app/user";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import triangleDown from "../../assets/img/triangleDown.png";
import triangleUp from "../../assets/img/triangleUp.png";
import nomail from "../../assets/img/nomail.png";
import {ArrowDownward, DeleteOutlineOutlined, EditOutlined, Face} from "@material-ui/icons";
import {getItem} from "../../services/LocaleStorage";
import PopupImportOpBancaire from "./PopupImportOpBancaire";
import OperationPopup from "./OperationPopup";
import {toast} from "react-toastify";
import {getBankAccount, getDatesFilter, getOptional} from "../../app/filters";
import PopupDelTransactions from "./PopupDelTransactions";
import PopupBlockTransactions from "./PopupBlockTransactions";
import {setLoaderSubscribe, setLoaderUnsubscribe} from "../../app/loader";
import Pagination from "../Pagination";
import {PulseLoader} from "react-spinners";
import PopupMessagerieClientOperation from "../Admin N/Messagerie/PopupMessagerieClientOperation";
import PopupConfirmFecExport from "./PopupConfirmFecExport";
import PopupConfirmUnblock from "./PopupConfirmUnblock";
import PopupEnforceAffectation from './PopupEnforceAffectation';
import lock from "../../assets/img/lock.png"
import unlock from "../../assets/img/unlock.png"
import exportArrow from "../../assets/img/exportArrow.png"
import lockWhite from "../../assets/img/lockWhite.png"
import lockFilter from "../../assets/img/lockFilter.png"
import unlockFilter from "../../assets/img/unlockFilter.png"
import edit from "../../assets/img/edit.png";
import lockLibelle from "../../assets/img/lockLibelle.png";
import blockFilterBlack from "../../assets/img/blockFilterBlack.png";
import unblockFilterBlack from "../../assets/img/unblockFilterBlack.png";

const OperationsBancaires = ({ refreshStatsAndGraphs }) => {

    const [operations, setOperations] = useState({ data: [], count: 0 })
    const [fetchRules, setFetchRules] = useState({ page: 1, per_page: 10 })
    const dispatch = useDispatch()
    const [loadingComponent, setLoadingComponent] = useState(false)
    const [msgModalOpen, setMsgModalOpen] = useState(false);
    const [unblockOpModale, setUnblockOpModale] = useState(null);
    const [opNotEditable, setOpNotEditable] = useState([]);
    const [rowToAffect, setRowToAffect] = useState({});
    const [openAffectOpBancaireModale, setOpenAffectOpBancaireModale] = useState(false);
    const [isAffectationEnforcingModalOpen, setIsAffectationEnforcingModalOpen] = useState(false);
    const [isAffectationEnforcingLoading, setIsAffectationEnforcingLoading] = useState(false);
    const [operationIdAffectToBeEnforced, setOperationIdAffectToBeEnforced] = useState(null);
    const [afficheDerouleOp, setAfficheDerouleOp] = useState(false);
    const [afficheDeleteModale, setAfficheDeleteModale] = useState(false);
    const [afficheBlockModale, setAfficheBlockModale] = useState(false);
    const [afficheFecExportModale, setAfficheFecExportModale] = useState(false);
    const [toggleRows, setTtoggleRows] = useState([]);
    const [sortTerms, setSortTerms] = useState("operation_date");
    const [sortOrder, setSortOrder] = useState("DESC");
    const optional = useSelector(getOptional)
    const [badges, setBadges] = useState({});
    const [displayBlockAllButton, setDisplayBlockAllButton] = useState(true);
    const [displayBlockedRow, setDisplayBlockedRow] = useState(true)
    const [displayUnblockedRow, setDisplayUnblockedRow] = useState(true)

    const [totals, setTotals] = useState({
        credit: "--",
        debit: "--",
        vat: "--"
    });

    const [buttonsTableFilters, setButtonsTableFilters] = useState(
        {
            receipt: ["with", "to_be_modified", "without"],
            affectation: ["with", "to_be_modified", "without"],
            payment: ["fully", "partially", "zero"],
        }
    )

    const updateBoutonTableauFilters = (type, value) => {
        if (buttonsTableFilters[type].includes(value)) {
            setButtonsTableFilters({ ...buttonsTableFilters, [type]: buttonsTableFilters[type].filter(item => item !== value) })
        } else {
            setButtonsTableFilters({ ...buttonsTableFilters, [type]: [...buttonsTableFilters[type], value] })
        }
    }

    const [opParent, setOpParent] = useState({});
    useEffect(() => {
        if (opParent.id) {
            const newOpParent = operations.data.find(el => el.id === opParent.id) ?? {}
            setOpParent(newOpParent)
        }
    }, [operations]);


    const toggleSubRows = (opId) => {
        if (toggleRows.some(el => el === opId)) {
            //hidden subRows
            const updatedSubRows = toggleRows.filter(id => id !== opId)
            setTtoggleRows(updatedSubRows)
        } else {
            setTtoggleRows((prevStat) => [...prevStat, opId])
        }
    }

    //Affiche la ligne d'ajout d'op bancaire
    const [afficheRow, setAfficheRow] = useState(false);
    const realUser = useSelector(getRealUser)
    const frontUser = useSelector(getUser)
    const [adminN, setAdminN] = useState(null)
    const bankAccount = useSelector(getBankAccount)
    const token = getItem('bbbToken')

    const dateFilters = useSelector(getDatesFilter)

    const updateFetchRules = (newValues) => {
        let rules = {};
        if (newValues.page && fetchRules.page !== newValues.page) {
            rules = { ...rules, page: newValues.page }
        }
        if (newValues.per_page && fetchRules.per_page !== newValues.per_page) {
            rules = { ...rules, per_page: newValues.per_page }
        }

        setFetchRules((prevRules) => ({
            ...prevRules,
            ...rules,
        }));
    };

    useEffect(() => {
        // We must have the AdminN so that we can send him a message
        // if the logged user is a client nnot admin N
        if (!adminN && realUser.role == 'client') {
            fetchAdminN()
        }
    }, [fetchRules]);

    useEffect(() => {
        refreshStatsAndGraphs();
    }, [dateFilters[0], dateFilters[1], bankAccount]);

    useEffect(() => {
        fetchOperationsBancaires();
    }, [dateFilters[0], dateFilters[1], fetchRules, bankAccount]);

    // lorsque les filtres changent, on doit demander la page 1, et non pas la page courante
    // Donc on actualise fetchRules qui à son tour va déclencher le useEffect du dessus
    useEffect(() => {
        updateFetchRules({ page: 1 });
    }, [dateFilters, bankAccount, adminN, optional, sortOrder, sortTerms, buttonsTableFilters, displayBlockedRow, displayUnblockedRow]);

    const fetchOperationsBancaires = async (perPage = fetchRules.per_page, page = fetchRules.page) => {
        setLoadingComponent(true)
        setOperations({ data: [], count: 0 })

        let body = {
            client_id: frontUser.id,
            bank_account_id: bankAccount.id,
            page: page,
            per_page: perPage,
            from_operation_date: dateFilters[0] ?? null,
            to_operation_date: dateFilters[1] ?? null,
            sort: {
                field: sortTerms,
                order: sortOrder
            },
            filters: {
                ...optional,
                isBlocked: displayUnblockedRow === displayBlockedRow ? "all" : displayBlockedRow ? "blocked" : "unblocked"
            },
            badges:  buttonsTableFilters

        }
        let response = await fetch(process.env.REACT_APP_API_URL +'/filter-transactions' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(body)

        })
        if (response.status === 200) {
            let body = await response.json()

            setOperations({ data: body.data, count: body.count })
            setBadges({ ...body.badges })
            setTotals({...body.totals})
            setLoadingComponent(false)
        } else {
            toast.error('Erreur de chargement des opérations bancaires')
            setLoadingComponent(false)
        }
    }

    /**
     * <<<<<<<<<< Chat related logic
     */
    const openMsgModale = (operation) => {
        if (!adminN && realUser.role == 'client') {
            toast.warning('Vous ne pouvez pas envoyer des messages, car vous n\'avez pas encore un admin n attaché!');
            return
        }

        setRowToAffect(operation);
        setMsgModalOpen(true);
    }

    const fetchAdminN = async () => {
        fetch(`${process.env.REACT_APP_API_URL}/${frontUser.id}/admins_n`, {
            method: 'GET', headers: {
                'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token,
            },
        })
        .then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setAdminN(data[0])
                })
            } else if (!response.ok) {
                return new Promise((resolve, reject) => reject('Une erreur s\'est produite! Veuillez verifier votre requete et re-essayez'))
            }
        });
    }

    /**
     *
     * When the adminN sends a message from the client account,
     * the sender should be adminN as the receiver must be client account in subject
     * @returns User
     */
    const getCorrespondant = () => {
        // If the admin (realUser) is logged to the client(frontUser) space
        // the correspondant will be the client(frontUser)
        if (frontUser.id != realUser.id) return frontUser

        return adminN
    }

    const mailBadgeColor = (conversation) => {
        if (conversation && conversation.status == 'closed') return '#717579' // Gray

        return '#ff9d41' // Orage
    }

    /**
     * Chat related logic >>>>>>>>>>
     */
    const openAffectModale = (op) => {
        setRowToAffect(op);
        //vaut vide pr le moment
        setOpenAffectOpBancaireModale(true);

    }
    const openAffectationEnforcingModal = (operationId) => {
        setOperationIdAffectToBeEnforced(operationId);
        setIsAffectationEnforcingModalOpen(true);
    }
    const closeAffectationEnforcingModal = () => {
        setIsAffectationEnforcingModalOpen(false);
    }
    const openDeroule = (e, op) => {
        e.stopPropagation();
        setOpParent(op);
        setAfficheDerouleOp(true);
    }
    const close = () => {
        setOpenAffectOpBancaireModale(false);
        setAfficheDerouleOp(false);
        setAfficheDeleteModale(false);
        setAfficheBlockModale(false);
        setMsgModalOpen(false);
        setAfficheFecExportModale(false);
        setUnblockOpModale(null);
    }

    const enforceAffectation = (choice) => {
        setIsAffectationEnforcingLoading(true);

        const token = getItem('bbbToken');

        fetch(`${process.env.REACT_APP_API_URL}/transaction/${operationIdAffectToBeEnforced}/affectation/enforce`, {
            method: 'POST',
            requirements: token,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ choice })
        })
        .then((response) => {
            if (response.ok) {
                setIsAffectationEnforcingLoading(false);
                closeAffectationEnforcingModal(false);
                fetchOperationsBancaires();
                toast.success("l'opération a réussi");
            } else {
                setIsAffectationEnforcingLoading(false);
                closeAffectationEnforcingModal(false);
                toast.error("l'opération a echoué");
            }
        })
        .catch(() => {
            setIsAffectationEnforcingLoading(false);
            closeAffectationEnforcingModal(false);
            toast.error("l'opération a echoué");
        })
    }

    const newManualRow = () => {
        const token = getItem('bbbToken');
        setAfficheRow(false);
        // dispatch(setLoaderSubscribe());
        const form = new FormData(document.getElementById("new-manual-row"))
        const monttc = Number(document.getElementById('new-operation-monttc').value)
        const vat = Number(document.getElementById('new-operation-vat').value)
        const amount = monttc - (monttc * vat / 100)

        // monttc / (1 + (vat/100)) = montHT
        // montHT * (1 + (vat/100))
        console.log(monttc, vat, amount)

        form.append('client_id', frontUser.id)
        form.append('amount', monttc / (1 + (vat / 100)).toFixed(2))
        form.append('vat_value', (amount * (vat / 100)).toFixed(2))
        form.append('vat_blocked', 'false')
        form.append('code_fiscal', '206000')
        form.append('bank_id', bankAccount.id)

        setLoadingComponent(true)

        // Création d'une opération bancaire
        fetch(process.env.REACT_APP_API_URL + '/transaction', {
            method: 'POST', requirements: token, headers: {
                'Authorization': 'Bearer ' + token
            }, body: form
        }).then((response) => {
            if (response.ok) {
                // dispatch(setLoaderUnsubscribe());
                setLoadingComponent(false)
                response.json().then(() => {
                    toast.success('Ajout de l\'opération bancaire avec succès')
                    fetchOperationsBancaires()
                    refreshStatsAndGraphs()
                });
            } else {
                // dispatch(setLoaderUnsubscribe());
                setLoadingComponent(false)
                toast.error('L\'ajout de l\'opération bancaire a échoué.')
            }
        })

    }
    const ifOpDeleteChecked = () => {
        if (document.querySelectorAll('.delete-operation-checkbox:checked').length > 0) {
            setAfficheDeleteModale(true)
        }
    }

    const checkAllBlockOp = () => {
        // tableau des opérations non bloquées pour les bloquer lors du clic sur "tous"
        const opFiltered = operations.data.filter(el => el.is_not_editable !== true && el.sub_category !== null)
        setOpNotEditable(opFiltered)
    }

    const ifOpFecChecked = () => {
            setAfficheFecExportModale(true)
    }
    const ifOpBlockedUnchecked = (op) => {
        if(!op.is_not_editable ){
            // clic sur cadenat ouvert
            setOpNotEditable([op])
        } else{
            setUnblockOpModale(op)
        }
    }

    useEffect(() => {
        if(opNotEditable.length > 0) {
            setAfficheBlockModale(true)
        }else{
            setAfficheBlockModale(false)
        }
        console.log(opNotEditable)
    }, [opNotEditable]);


    const unblockOp = () => {
        setLoadingComponent(true);
        fetch(process.env.REACT_APP_API_URL + '/transaction/unblock', {
            method: 'POST', 
            'Access-Control-Allow-Credentials': true, 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, 
            }, 
            body: JSON.stringify({id: unblockOpModale.id})
        })
        .then((data) => {
            setLoadingComponent(false);
            dispatch(setLoaderUnsubscribe())
            if (data.ok) {
                fetchOperationsBancaires()
                toast.success("L'opération bancaire a été débloquée avec succès.")
            } else {
                toast.error("L'opération bancaire n'a pas été débloquée.")
            }
            setUnblockOpModale(null)
        })
        .catch((error) => {
            setLoadingComponent(false);
            console.error(error);
        });
    }

    useEffect(() => {
        operations.data.some(el => !el.is_not_editable && el.sub_category !== null) ? setDisplayBlockAllButton(true) : setDisplayBlockAllButton(false)
    }, [operations]);

    const deleteOpChecked = () => {
        const inputs = document.querySelectorAll('.delete-operation-checkbox:checked')
        const ids = Array.from(inputs).map(input => {
            return parseInt(input.dataset.operationId)
        })
        setOpParent({})
        // dispatch(setLoaderSubscribe())
        setLoadingComponent(true)

        fetch(process.env.REACT_APP_API_URL + '/transaction', {
            method: 'DELETE', 
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${token}`
            }, 
            body: JSON.stringify({transactions_id: ids})
        })
        .then((data) => {
            // dispatch(setLoaderUnsubscribe())
            setLoadingComponent(false)
            if (data.ok) {
                fetchOperationsBancaires()
                refreshStatsAndGraphs()
                toast.success('La suppression a été effectuée avec succès.')
            } else {
                data.json().then(res => {
                    const message = res.message ?? 'La suppression a échouée.';
                    toast.error(message);
                });
            }
            close();
        })
        .catch((error) => {
            console.error(error);
            toast.error('La suppression a échouée.');
            close();
        });
    }


    const blockOpChecked = () => {
        setLoadingComponent(true);
        dispatch(setLoaderSubscribe())
        fetch(process.env.REACT_APP_API_URL + '/transactions/block', {
            method: 'POST', 'Access-Control-Allow-Credentials': true, headers: {
                'Authorization': 'Bearer ' + token, 'Content-Type': "application/json"
            }, body: JSON.stringify({transactions_id: opNotEditable.map(el => el.id)})
        })
        .then(async (data) => {
            setLoadingComponent(false);
            dispatch(setLoaderUnsubscribe())
            const response = await data.json()
            if (data.ok) {
                fetchOperationsBancaires()
                toast.success("Le blocage des opérations bancaires a été effectué avec succès.")
            } else {
                toast.error(response.message ?? "Le blocage des opérations bancaires a échoué.")
            }
            setOpNotEditable([])
        })
    }

    const changePerPage = async (e) => {
        const perPage = parseInt(e.target.value);
        updateFetchRules({ per_page: perPage });
    }

	const displaySubCategoryName = (operation) => {
		let subCategoryName = '';

		if (operation?.sub_category?.display_name) {
			subCategoryName = operation?.sub_category?.display_name;
		} else {
			// return <HelpIcon fontSize="small" />;
			return null;
		}

		if (operation?.is_sub_categ_suggested) {
			return <span style={{ color: '#FD5353' }}>{subCategoryName} <HelpIcon fontSize="small" /></span>
		} else {
			return <span style={{ color: '#818181' }}>{subCategoryName}</span>
		}
	}

    const displayOperationLabel = (operation) => {
        let operationLabel;
        const remittanceInfo = JSON.parse(operation.remittance_information);
        if (remittanceInfo) {
            operationLabel = remittanceInfo.map((line, index) => (
                <React.Fragment key={index}>
                    {index == 0 ? <span className="font-bold">{line}</span> : <small>{line}</small>}
                    <br />
                </React.Fragment>
            ))
        } else {
            operationLabel = <>{operation.label}</>
        }

        return <span className="max-w-[550px] block whitespace-normal">{operationLabel}</span>;
    }

	const styles = {
		subCategoryName: {
			fontSize: '14px',
			fontWeight: '600',
		},
		operationLabel: {
			fontSize: '16px',
			fontWeight: '600',
		}
	}

    const renderRow = () => {
        let row = []
        if (afficheRow) {
            row.push(<form id="new-manual-row">
                <table className="min-w-full table overflow-hidden rounded-2xl mt-6">
                    <thead>
                    <tr className="capitalize text-sm text-center bg-grisbleu p-8">
                        <th className="w-24">
                            <div className="flex pl-6 justify-center items-center">
                                <input name="operation_date" type="date" className="bg-none-input mr-2" size="4"/>
                            </div>
                        </th>
                        <th className="w-8">
                            <div className="flex items-center">
                                <input name="label" type="text" className="bg-none-input mr-2 text-red-500" size="8"
                                       placeholder="label"/>
                            </div>
                        </th>

                        <th className="none-mobile w-24">
                            <div className="text flex items-center">
                                <select name="type" className="bg-none-input">
                                    <option value="">Selectionner le type</option>
                                    <option value="recette">Crédit</option>
                                    <option value="depense">Débit</option>
                                </select>
                            </div>
                        </th>
                        <th className="none-mobile w-24">
                            <div className="text flex items-center">
                                <input type="number" id="new-operation-monttc" className="bg-none-input mr-2" name="amount_with_vat" placeholder="Mont TTC"/>
                            </div>
                        </th>
                        <th className="none-mobile w-24">
                            <div className="text flex items-center">
                                <input type="number" id="new-operation-vat" name="vat" className="bg-none-input mr-2" size="4" placeholder="TVA"/>
                            </div>
                        </th>
                        <th className="none-mobile w-24"></th>

                        <th className="w-24">
                            <div className="flex px-6 justify-center items-end">
                                <button type="button" onClick={newManualRow}
                                        className="ml-2 bg-lilaFonce text-white p-2 rounded-lg"><ArrowDownward/>
                                </button>
                            </div>
                        </th>

                    </tr>
                    </thead>
                </table>
            </form>)
        }
        return (row)
    }

    return (<React.Fragment>

        {openAffectOpBancaireModale && <PopupImportOpBancaire rowToAffect={rowToAffect} close={close} fetchOperationsBancaires={fetchOperationsBancaires} refreshStatsAndGraphs={refreshStatsAndGraphs} />}
        {isAffectationEnforcingModalOpen && <PopupEnforceAffectation enforceAffectation={enforceAffectation} closePopup={closeAffectationEnforcingModal} isLoading={isAffectationEnforcingLoading} /> }
        <div className="">{renderRow()}</div>

        <div className="overflow-auto rounded-lg w-full py-8">
            <table className="w-full">
                <thead className="bgPurpuleCustom text-white thead-rounded">
                <tr>
                    <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left">
                        <div className="flex flex-col justify-start">
                            <div className="flex items-center tracking-wide whitespace-nowrap"> 
                                <span>Date</span>&nbsp;
                                <img 
                                    src={triangleDown} 
                                    alt="Triangle vers le bas" 
                                    className="mx-1 w-3.5 h-3.5 cursor-pointer" 
                                    onClick={() => {
                                        setSortTerms("operation_date"); 
                                        setSortOrder("DESC");
                                    }}
                                /> 
                                <img 
                                    src={triangleUp} 
                                    alt="Triangle vers le haut" 
                                    className="w-3.5 h-3.5 cursor-pointer" 
                                    onClick={() => {
                                        setSortTerms("operation_date"); 
                                        setSortOrder("ASC");
                                    }}
                                />
                            </div>
                        </div>
                        <span className="invisible">invisible</span>
                    </th>
                    <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left">
                        <div className="flex flex-col justify-start">
                            <span>Libellé des opérations</span>
                            <div className="flex">
                                <span className={`px-1.5 py-1 text-base rounded-full mr-2 cursor-pointer bg-white `} onClick={() => {
                                    setDisplayBlockedRow(!displayBlockedRow)
                                }}>
                                   <img src={displayBlockedRow ? blockFilterBlack : lockFilter} alt="lock"/>


                                </span>
                                <span className={`px-1.5 py-1 text-base rounded-full cursor-pointer bg-white`} onClick={() => {
                                    setDisplayUnblockedRow(!displayUnblockedRow)
                                }}><img src={displayUnblockedRow ? unblockFilterBlack : unlockFilter} alt="unlock"/></span>

                            </div>
                        </div>
                    </th>
                    <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left">
                        <div className="flex flex-col justify-start">
                            <span></span>
                            <span className="invisible">invisible</span>
                        </div>
                    </th>
                    <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left">
                    <div className="flex flex-col justify-start ">
                        <div className="flex items-center tracking-wide whitespace-nowrap">  
                            <span >Débit</span>
                            <img 
                                src={triangleDown} 
                                alt="Triangle vers le bas" 
                                className="mx-1 w-3.5 h-3.5 cursor-pointer"
                                onClick={() => {
                                    setSortTerms("amountWithVat"); 
                                    setSortOrder("DESC");
                                }} 
                            /> 
                            <img 
                                src={triangleUp} 
                                alt="Triangle vers le haut" 
                                className="w-3.5 h-3.5 cursor-pointer"
                                onClick={() => {
                                    setSortTerms("amountWithVat"); 
                                    setSortOrder("ASC");
                                }} 
                            />
                        </div>
                        <p className="whitespace-nowrap">
                            <span className="inline-flex items-center justify-center h-6 w-6 text-sm rounded-full text-lilaFonce bg-white mr-2">-</span>
                            <span>{totals.debit} €</span>
                        </p>
                    </div>
                    </th>
                    <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left">
                    <div className="flex flex-col justify-start">
                        <div className="flex items-center tracking-wide whitespace-nowrap">
                            <span>Crédit</span>
                            <img 
                                src={triangleDown} 
                                alt="Triangle vers le bas" 
                                className="mx-1 w-3.5 h-3.5 cursor-pointer"
                                onClick={() => {
                                    setSortTerms("amountWithVat"); 
                                    setSortOrder("DESC");
                                }} 
                            /> 
                            <img 
                                src={triangleUp} 
                                alt="Triangle vers le haut" 
                                className="w-3.5 h-3.5 cursor-pointer"
                                onClick={() => {
                                    setSortTerms("amountWithVat"); 
                                    setSortOrder("ASC");
                                }} 
                            />
                        </div>
                        <p className="whitespace-nowrap">
                            <span className="inline-flex items-center justify-center h-6 w-6 text-sm text-lilaFonce bg-white rounded-full mr-2">+</span>
                            <span>{totals.credit} €</span>
                        </p>
                    </div>
                    </th>
                    <th className="py-4 px-3 text-sm font-semibold tracking-wide text-left">
                    <div className="flex flex-col justify-start">
                        <div className="flex items-center tracking-wide whitespace-nowrap">
                            <span>TVA</span>
                            <img 
                                src={triangleDown} 
                                alt="Triangle vers le bas" 
                                className="mx-1 w-3.5 h-3.5 cursor-pointer"
                                onClick={() => {
                                    setSortTerms("vat"); 
                                    setSortOrder("DESC");
                                }} 
                            /> 
                            <img 
                                src={triangleUp} 
                                alt="Triangle vers le haut" 
                                className="w-3.5 h-3.5 cursor-pointer"
                                onClick={() => {
                                    setSortTerms("vat"); 
                                    setSortOrder("ASC");
                                }} 
                            />
                        </div>
                        <p className="whitespace-nowrap">
                            <span className="inline-flex items-center justify-center h-6 w-6 text-sm text-lilaFonce bg-white rounded-full mr-2">+</span>
                            <span>{totals.vat} €</span>
                        </p>
                    </div>
                    </th>
                    <th className="py-4 px-3 text-sm font-semibold tracking-wide text-center">
                        <div className="flex flex-col justify-start">
                            <div>Pièces</div>
                            <div className="flex justify-center gap-2 mt-2">
                                <div className={`text-xs rounded-full ${!buttonsTableFilters.receipt.includes("with") ? 'text-white ' : 'text-vertBouton' }`}>
                                    <span onClick={() => updateBoutonTableauFilters('receipt', 'with')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-vertBouton ${!buttonsTableFilters.receipt.includes("with") ? 'bg-white text-vertBouton' : 'text-white bg-vertBouton' }`}>{badges.receipt?.with ?? "--"}</span>
                                </div>
                                <div className={`text-xs rounded-full ${!buttonsTableFilters.receipt.includes("to_be_modified") ? 'text-white ' : 'text-orange-500' }`}>
                                    <span onClick={() => updateBoutonTableauFilters('receipt', 'to_be_modified')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-orange-500 ${!buttonsTableFilters.receipt.includes("to_be_modified") ? 'bg-white text-orange-500' : 'text-white bg-orange-500' }`}>{badges.receipt?.to_be_modified ?? "--"}</span>
                                </div>
                                <div className={`text-xs rounded-full ${!buttonsTableFilters.receipt.includes("without") ? 'text-white ' : 'text-orange-500' }`}>
                                    <span onClick={() => updateBoutonTableauFilters('receipt', 'without')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-redCorail ${!buttonsTableFilters.receipt.includes("without") ? 'bg-white text-redCorail' : 'text-white bg-redCorail' }`}>{badges.receipt?.without ?? "--"}</span>
                                </div>
                            </div>
                        </div>
                    </th>
                    <th className={`py-4 px-3 text-sm font-semibold tracking-wide text-center ${realUser.role === "client" ? "th-rounded-top-right" : ""}`}>
                        <div className="flex flex-col">
                            <div>Statut</div>
                            <div className="flex justify-center gap-2 mt-2">
                                <div className={`text-xs rounded-full ${!buttonsTableFilters.affectation.includes("with") ? 'text-white ' : 'text-vertBouton' }`}>
                                    <span onClick={() => updateBoutonTableauFilters('affectation', 'with')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-vertBouton ${!buttonsTableFilters.affectation.includes("with") ? 'bg-white text-vertBouton' : 'text-white bg-vertBouton' }`}>{badges.affectation?.with ?? "--" }</span>
                                </div>
                                <div className={`text-xs rounded-full ${!buttonsTableFilters.affectation.includes("to_be_modified") ? 'text-white ' : 'text-orange-500' }`}>
                                    <span onClick={() => updateBoutonTableauFilters('affectation', 'to_be_modified')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-orange-500 ${!buttonsTableFilters.affectation.includes("to_be_modified") ? 'bg-white text-orange-500' : 'text-white bg-orange-500' }`}>{badges.affectation?.to_be_modified ?? "--"}</span>
                                </div>
                                <div className={`text-xs rounded-full ${!buttonsTableFilters.affectation.includes("without") ? 'text-white ' : 'text-orange-500' }`}>
                                    <span onClick={() => updateBoutonTableauFilters('affectation', 'without')}  className={`text-xs px-2 py-0.5 rounded-full border-2 border-redCorail ${!buttonsTableFilters.affectation.includes("without") ? 'bg-white text-redCorail' : 'text-white bg-redCorail' }`}>{badges.affectation?.without ?? "--"}</span>
                                </div>
                            </div>
                        </div>
                    </th>
                    {/*btn*/}
                    {realUser.role !== "client" ? <th className="text-sm font-semibold  relative p-4"> {realUser.role !== "client" && !afficheRow ? <button
                        className="btn-add"
                        onClick={() => setAfficheRow(true)}>+</button> : realUser.role !== "client" && afficheRow ? <button
                        className="btn-add"
                        onClick={() => setAfficheRow(false)}>-</button> : ""}</th> : null}


                    {realUser.role !== 'client' && <th className="p-4 font-semibold whitespace-nowrap bg-white">
                        <div className="flex justify-center items-center px-4 cursor-pointer" onClick={ifOpFecChecked}>
                            <img src={exportArrow} className="exportFec" alt=""/>
                            <span className="font-semibold text-[#9D9D9D] text-[14px] sidebar:text-[20px] ml-2">Export FEC</span>
                        </div>
                    </th>}

                </tr>
                    </thead>

                    {loadingComponent ? <tbody>
                    <tr>
                        <td className="p-0" colSpan="3">
                            <PulseLoader color={'purple'} size={10}/>
                        </td>
                    </tr>
                    </tbody> : operations === null || operations.data.length === 0 ? <tbody>
                    <tr>
                        <td className="p-4" colSpan="3">Vous n'avez aucune opération bancaire</td>
                    </tr>
                    </tbody> : <tbody className="">
                    {operations.data.map((operation, index) => {
                        return !operation.has_parent && <React.Fragment key={operation.id}>
                            <tr onClick={() => {
                                toggleSubRows(operation.id)
                            }} className={`${realUser.role === "admin_n" || realUser.role === "admin_p" ? "except-last-td" : ""} ${index % 2 === 0 ? "bg-grisbleu" : ""}`}>
                                <td className="p-3 text-sm text-black whitespace-nowrap border-b border-gray-200">{operation.displayable_operation_date}</td>
                                <td className="p-3 text-sm text-black whitespace-nowrap border-b border-gray-200">
                                    <span style={styles.subCategoryName}>
                                        {displaySubCategoryName(operation)}
                                    </span> 
                                    <p className="flex items-start" style={styles.operationLabel}>
                                        {operation.is_not_editable ? <img src={lockLibelle} alt="locked" className="w-[12px] h-[15px] mr-2"/> : null} 
                                        {displayOperationLabel(operation)}
                                        <button className="text-black pr-2" onClick={(e) => openDeroule(e, operation)}>
                                            <ArrowDropDownIcon/>
                                        </button>
                                    </p>
                                </td>
                                <td className="p-3 text-sm text-black whitespace-nowrap border-b border-gray-200" onClick={() => {
                                    openMsgModale(operation)
                                }}>
                                    <div className='mail-container'>
                                        <img src={nomail} className="img-mailround" alt=""/>
                                        {operation.conversation && parseInt(operation.conversation.unread_messages_count) > 0 ? <span className='mail-badge'
                                                                                                                                      style={{background: mailBadgeColor(operation.conversation)}}>{operation.conversation ? operation.conversation.unread_messages_count : ''}</span> : ''}
                                    </div>
                                </td>
                                <td className="p-3 text-sm text-black whitespace-nowrap border-b border-gray-200 text-right">{operation.type === 'achat' ? operation.amount_with_vat : ""} €</td>
                                <td className="p-3 text-sm text-black whitespace-nowrap border-b border-gray-200 text-right">{operation.type === 'vente' ? operation.amount_with_vat + "€" : ""} </td>
                                <td className="p-3 text-sm whitespace-nowrap border-b border-gray-200 text-right"> <span className="mr-2 text-gray-700">{operation.vat_value ?? operation.vat_value }</span>
                                    <span>{operation.vat ?? operation.vat }%</span></td>
                                <td className="p-3 text-sm text-black whitespace-nowrap border-b border-gray-200">
                                <div className="flex justify-center">
                                    <div className="relative p-0">
                                        {operation.has_children ? null :
                                            operation.affectation.length !== 0 && operation.affectation[0].invoice ?
                                                <a href={process.env.REACT_APP_STORAGE_URL + operation.affectation[0].invoice.receipt_path} target="_blank" rel="noreferrer">
                                                    <AttachFileIcon className="fill-black"/>
                                                </a> 
                                            : 
                                                ['admin_p', 'admin_n'].includes(realUser.role) ?
                                                    <button type="button" onClick={() => openAffectationEnforcingModal(operation.id)}>
                                                        <AttachFileIcon className="fill-black"/>
                                                    </button>
                                                :
                                                    <button type="button">
                                                        <AttachFileIcon className="fill-black"/>
                                                    </button>
                                        }
                                        {operation.is_affectation_enforced && !operation.is_affected ? 
                                            <span className={`top-icon-rounded bg-orange-500`}></span> 
                                        :
                                            operation.is_affected && !operation.has_children ? 
                                                <span className={`top-icon-rounded bg-greenSuccess`}></span> 
                                            : 
                                                !operation.has_children ?
                                                    <span className={`top-icon-rounded bg-redCorail`}></span> 
                                                : 
                                                    null
                                        }
                                    </div>
                                </div>
                            </td>
                                <td className="p-3 text-sm text-black whitespace-nowrap border-b border-gray-200 ">
                                    <div className="flex justify-center">
                                    {
                                        operation.has_children ? null :
                                            operation.sub_category && !operation.is_sub_categ_suggested ?
                                                operation.is_not_editable ?
                                                    <div className={`rounded-full flex items-center justify-center w-11 h-11 ${operation.is_reported ? "bg-orange-500" : "bg-greenSuccess"}`}>
                                                        <img src={lockWhite} alt="lock"/>
                                                    </div>
                                                    :
                                                    <button className={`rounded-full flex items-center justify-center p-3 ${operation.is_reported ? "bg-orange-500" : "bg-greenSuccess"}`} onClick={() => openAffectModale(operation)}>
                                                        <img src={edit} alt="edit"/>
                                                    </button>

                                                : <button className="bg-redCorail rounded-xl px-6 py-2 text-white" onClick={() => openAffectModale(operation)}>Catégoriser</button>
                                    }

                                    </div>
                                </td>

                                {realUser.role !== 'client' ? <>
                                    <td className="border-b border-gray-200"/>

                                    <td className="h-full p-0">
                                        <div className="flex items-center justify-center h-12 ">
                                            {operation.is_not_editable ?
                                                <>
                                                    <div className="p-2 w-12 h-12 cursor-pointer" onClick={() => ifOpBlockedUnchecked(operation)} data-operation-id={operation.id}>
                                                        <img src={lock} alt="lock"/>
                                                    </div>
                                                    <div className="p-2 w-[2rem] h-[2rem]"></div>
                                                </>
                                            : 
                                                <>
                                                    <div className="p-2 w-12 h-12 cursor-pointer" onClick={() => ifOpBlockedUnchecked(operation)} data-operation-id={operation.id}>
                                                        <img src={unlock} alt="lock"/>
                                                    </div>
                                                    <div className="p-2 w-[2rem] h-[2rem]">
                                                        <input type="checkbox" 
                                                            data-operation-id={operation.id}
                                                            className="delete-operation-checkbox w-full h-full"
                                                        />
                                                        {/*nouvelles checkbox de suppression*/}
                                                        {/*{ 1 ? <img src={checkOn} alt="" className="block-operation-checkbox"  onClick={() => ifOpBlockedUnchecked(operation)}/> : <img src={checkOff} alt="" className="block-operation-checkbox"   onClick={() => ifOpBlockedUnchecked(operation)}/>}*/}
                                                    </div>
                                                </>
                                            }
                                        </div>

                                    </td>
                                </> : null}

                            </tr>

                            {// rows child
                                operation.has_children && toggleRows.some((opId) => opId === operation.id) && operation.children.map(child => {
                                    return <tr key={child.id}
                                               className={`${realUser.role === "admin_n" || realUser.role === "admin_p" ? "except-last-td" : ""} ${index % 2 === 0 ? "bg-grisbleu" : ""} text-center text-black `}>
                                        <td className="w-min border-b border-gray-200 text-left p-3 whitespace-nowrap text-left text-sm">
                                            <p>{child.displayable_operation_date}</p>
                                        </td>
                                        <td className="w-min border-b border-gray-200 p-3 whitespace-nowrap text-left text-sm text-black">
                                            {child?.sub_category?.display_name ? 
                                                <span>{child?.sub_category?.display_name}</span> 
                                            : 
                                                null
                                            }
                                            <p className="flex items-center">{child.label}</p>
                                        </td>
                                        <td className="border-b border-gray-200 p-3 text-sm" onClick={() => {
                                            openMsgModale(child)
                                        }}>
                                            <div className='mail-container'>
                                                <img src={nomail} className="img-mailround" alt=""/>
                                                {child.conversation && parseInt(child.conversation.unread_messages_count) > 0 ? 
                                                    <span className='mail-badge' style={{background: '#ff9d41'}}>
                                                        {child.conversation ? child.conversation.unread_messages_count : ''}
                                                    </span> 
                                                : 
                                                    ''
                                                }
                                            </div>
                                        </td>

                                        <td className="w-min border-gray-200 border-b p-3 whitespace-nowrap text-right text-sm text-black">
                                            <p>{child.type === 'achat' ? child.amount_with_vat : ""}</p>
                                        </td>
                                        <td className="w-min border-gray-200 border-b p-3 whitespace-nowrap text-right text-sm text-black">
                                            <p>{child.type === 'vente' ? child.amount_with_vat : ""}</p>
                                        </td>
                                        <td className="w-min border-b border-gray-200 p-3 whitespace-nowrap text-right text-sm">
                                            <span className="mr-2 text-black">{ child.vat_value }</span> <span>{ child.vat }%</span>
                                        </td>
                                        <td className="border-b border-gray-200">
                                            <div className="flex justify-center">

                                                <div className="relative p-0">
                                                    {
                                                        child?.affectation.length !== 0 && child?.affectation[0].invoice ?
                                                            <a href={process.env.REACT_APP_STORAGE_URL + child?.affectation[0].invoice.receipt_path} target="_blank" rel="noreferrer">
                                                                <AttachFileIcon className="fill-black"/>
                                                            </a> 
                                                        : 
                                                            ['admin_p', 'admin_n'].includes(realUser.role) ? 
                                                                <button type="button" onClick={() => openAffectationEnforcingModal(child.id)}>
                                                                    <AttachFileIcon className="fill-black"/>
                                                                </button>
                                                            :
                                                                <button type="button">
                                                                    <AttachFileIcon className="fill-black"/>
                                                                </button>

                                                    }
                                                    {child.is_affectation_enforced && !child.is_affected ?
                                                        <span className={`top-icon-rounded bg-orange-500`}></span> 
                                                    :
                                                        child.is_affected ? 
                                                            <span className={`top-icon-rounded bg-greenSuccess`}></span> 
                                                        :
                                                            <span className={`top-icon-rounded bg-redCorail`}></span>
                                                    }
                                                </div>

                                            </div>
                                        </td>

                                        <td className="colors border-b border-gray-200">
                                            <div className="flex justify-center">
                                                {
                                                    // ici
                                                    child.is_not_editable && realUser.role === "client" ?  <>
                                                            {child.sub_category !== null ?  <div className="bg-greenSuccess rounded-full flex items-center justify-center w-11 h-11">
                                                                    <img src={lockWhite} alt="lock"/>
                                                                </div>
                                                                : null}
                                                        </> :
                                                            <>
                                                                {child.sub_category !== null ? <button className="bg-greenSuccess rounded-full p-2" onClick={() => openAffectModale(child)}>
                                                                        <EditOutlined
                                                                            className="text-white"/></button>
                                                                    : <button className="bg-redCorail rounded-xl px-6 py-2 text-white" onClick={() => openAffectModale(child)}>Catégoriser</button>}
                                                            </>
                                                }
                                                {child.is_not_editable ? <button
                                                    className="ml-2 bg-lilaFonce text-white p-2 rounded-lg"><Face/></button> : null}
                                            </div>
                                        </td>
                                        <td  className="border-y border-gray-200"/>
                                        <td/>


                                        {/*Seul les admins peuvent bloquer/supprimer des op*/}
                                        {/*{realUser.role !== 'client' && <td className="h-full p-0">*/}
                                        {/*    <div className="flex items-center justify-center h-full checkbox-admin">*/}
                                        {/*        <div className="p-2"><input type="checkbox" defaultChecked={child.is_not_editable}*/}
                                        {/*                                    data-operation-id={child.id}*/}
                                        {/*                                    className={`block-operation-checkbox`}/>*/}
                                        {/*        </div>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="p-2"><input type="checkbox" data-operation-id={child.id}*/}
                                        {/*                                    className="delete-operation-checkbox"/></div>*/}
                                        {/*    </div>*/}

                                        {/*</td>}*/}


                                    </tr>
                                })}
                        </React.Fragment>


                    })}
                    {
                        realUser.role !== "client" ? 
                        <tr>
                            <td colSpan={9}></td>
                            <td>
                                <div className="flex justify-center mt-4">
                                    {displayBlockAllButton ? 
                                        <button><span className="text-[#B3B3B3] underline font-semibold" onClick={checkAllBlockOp}>Tous...</span></button>
                                    : null}
                                    <button className="ml-4 bg-[#D7D7D7] w-[32px] h-[32px] text-white p-[7px] rounded-lg flex justify-center items-center"
                                            onClick={ifOpDeleteChecked}>
                                        <DeleteOutlineOutlined/>
                                    </button>
                                </div>
                            </td>
                        </tr> : null
                    }
                    </tbody>}
            </table>
            {afficheDerouleOp && <OperationPopup close={close} opParent={opParent} fetchOperationsBancaires={fetchOperationsBancaires}/>}
            {afficheDeleteModale && <PopupDelTransactions closeModale={close} isLoadingComponent={loadingComponent} delOp={deleteOpChecked}/>}
            {afficheBlockModale && <PopupBlockTransactions closeModale={close} isLoadingComponent={loadingComponent} block={blockOpChecked} info="operation"/>}
            {afficheFecExportModale && <PopupConfirmFecExport closeModale={close} type="banque"/>}
            {msgModalOpen && <PopupMessagerieClientOperation correspondant={getCorrespondant()} closeModal={close} operation={rowToAffect}/>}
            {unblockOpModale && <PopupConfirmUnblock closeModale={close} isLoadingComponent={loadingComponent} unblock={unblockOp} info="operation"/>}
        </div>
        <div className="flex justify-between items-center">
                <div className="flex items-center font-semibold">
                    <p>Afficher:</p>
                    <div className="relative border border-gray-300 rounded-lg min-w-[84px] mx-2 px-4 py-2">
                        <select id="select-op-per-page" className="bg-white w-full rounded-lg" value={fetchRules.per_page} onChange={changePerPage}>
                            <option value={10}>10</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        <ArrowDropDownIcon pointerEvents="none" className="text-gray-400 right-2 top-1/2 -translate-y-1/2 absolute"/>
                    </div>
                    <p>par page</p>
                </div>
            <Pagination data={{ ...fetchRules, count: operations.count }} paginate={(per_page, page) => updateFetchRules({ per_page, page })}/>
        </div>
    </React.Fragment>)
}

export default OperationsBancaires